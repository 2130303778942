import React, { useContext, useEffect, useState } from 'react';
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { Box, Grid, Button, FormControlLabel, Checkbox } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';

import { UnitContext } from '../../../context/UnitContext';
import { useAppDispatch } from '../../../app/hooks';
import { selectProject } from '../../../features/project/projectSlice';
import { showErrorSnackbar } from '../../../features/snackbar/snackbarSlice';
import DealDeposits from '../DealDeposits';
import DealOptions from '../DealOptions';
import Dropdown from '../../common/formControls/Dropdown';
import TextInput from '../../common/formControls/TextInput';
import { IUnit, IDocuments, IUnitHistory } from '../../../types/unit';
import { IMerge, IMergeData } from '../../../types/merge';
import { numToCurrency, optionsLeft, createPdf, sortSuites } from '../../../utils/Functions';
import { IDealDeposit } from '../../../types/CreateDealForm';
import { IOption } from '../../../types/project';
import { selectUser } from '../../../features/auth/authSlice';

const UnitTransfer = (props: ChildProps) => {
  const {
    handleOptions,
    handleOptionDropdown,
    handleOptionInput,
    optionValue,
    depositList,
    createAmendment,
    createDeposits,
    selectedDeposit,
    setSelectedDeposit,
    pendingOptions,
    setPendingOptions,
    unitTransferTotal,
    setUnitTransferTotal,
    unitTransfer,
    setUnitTransfer,
  } = props;
  const { unit, deals, filteredDeal, documents, pendingDeposits, setPendingDeposits, document, rental, setRental } =
    useContext(UnitContext);
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);

  const [merges, setMerges] = useState<any[]>([]);
  const [availableUnits, setAvailableUnits] = useState<IUnit[]>([]);
  const [mergeNames, setMergeNames] = useState<string[]>([]);
  const [selectedMergeTemplates, setSelectedMergeTemplates] = useState<IMerge[]>([]);
  const [selectedUnitTransferMerge, setSelectedUnitTransferMerge] = useState<IMerge | null>(null);
  const [removePlan, setRemovePlan] = useState<boolean>(false);
  const [currentDeposits, setCurrentDeposits] = useState<boolean>(false);

  const [getUnitTransferMerges] = useLazyQuery<IMergeData>(MERGETEMPLATES, {
    variables: { filter: { project: project._id, type: 'UnitTransfer' } },
    onCompleted: (data) => {
      setMerges(data.mergeTemplateMany);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  useQuery(UNITS, {
    variables: { filter: { project: project._id } },
    onCompleted: (data) => {
      getUnitTransferMerges();
      let availableUnits = data.unitMany.filter((selectedUnit: IUnit) => {
        return (
          selectedUnit.status !== 'C' &&
          (selectedUnit.status !== 'F' || (selectedUnit.status === 'F' && selectedUnit.suite === unit.suite)) &&
          selectedUnit.status !== 'S' &&
          selectedUnit.status !== 'O' &&
          selectedUnit.status !== 'IP' &&
          selectedUnit.status !== 'P' &&
          selectedUnit.status !== 'UT'
        );
      });
      setAvailableUnits(sortSuites(availableUnits, 'suite'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateUnitTransfer] = useMutation(UPDATEUNIT, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  // Functions

  useEffect(() => {
    if (unitTransfer && unitTransfer.hasOwnProperty('rental') && unitTransfer.rental !== null) {
      setRental(
        pendingOptions.reduce((a: any, b: any) => {
          return a + b.rental * b.purchaseAmount;
        }, unitTransfer.rental)
      );
    }
  }, [pendingOptions, unitTransfer]);

  const handleDropdownInput = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    let mergeArray = merges.find((mergeTemplate: IMerge) => mergeTemplate.name === event.target.value);
    let checkMergeArray = selectedMergeTemplates.some((merge: IMerge) => mergeArray?.name === merge.name);
    if (checkMergeArray) {
      storeDispatch(showErrorSnackbar(`Template has already been added`));
    } else {
      setSelectedMergeTemplates([...selectedMergeTemplates, mergeArray!]);
    }
  };

  const handleUnitTransferInput = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    let mergeArray = merges.find((mergeTemplate: IMerge) => mergeTemplate.name === event.target.value);
    if (mergeArray) {
      setSelectedUnitTransferMerge(mergeArray);
    } else {
      storeDispatch(showErrorSnackbar(`Error selecting merge template`));
    }
  };

  const handleDeleteMergeTemplate = (name: string) => {
    let selectedMergeArray = selectedMergeTemplates.filter((mergeTemplate: IMerge) => mergeTemplate.name !== name);
    setSelectedMergeTemplates(selectedMergeArray);
  };

  const availableSuiteNames = () => {
    let suite = availableUnits.map((unit: IUnit, index: number) => {
      return unit.suite;
    });
    return suite;
  };

  const handleUnitDropdown = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    let selectedUnit: any = availableUnits.find((selectedUnit: IUnit) => {
      return selectedUnit.suite === event.target.value;
    });
    handleOptions();
    setUnitTransfer(selectedUnit);
    setUnitTransferTotal(selectedUnit.basePrice);
  };

  const handlePendingDepositsArray = (array: IDealDeposit[]) => {
    setPendingDeposits(array);
  };

  const handleUnitTransfer = async (e: any) => {
    e.preventDefault();
    if (!selectedUnitTransferMerge) return storeDispatch(showErrorSnackbar(`Unit Transfer Template Not Selected`));

    if (!currentDeposits && !selectedDeposit) {
      storeDispatch(showErrorSnackbar(`No Deposit Selected`));
      return;
    }

    if (!unitTransfer?.floorPlan) {
      storeDispatch(showErrorSnackbar(`No Floorplan Merge found`));
      return;
    }

    let pdf = await createPdf(
      selectedUnitTransferMerge,
      project,
      { ...unit, rental: filteredDeal.pendingRental ? filteredDeal.pendingRental : unit.rental ? unit.rental : null },
      filteredDeal.purchasers,
      filteredDeal.deposit,
      filteredDeal.options,
      null,
      { ...unitTransfer, rental: rental ? rental : unitTransfer.rental ? unitTransfer.rental : null },
      pendingOptions,
      pendingDeposits,
      filteredDeal,
      null,
      false,
      selectedMergeTemplates,
      [],
      removePlan
    );

    let sign = selectedUnitTransferMerge.signFields.map((sign: any) => {
      return {
        index: sign.index,
        key: sign.key,
        pageNumber: sign.pageNumber,
        x: sign.x,
        y: sign.y,
      };
    });

    let floorSign = [];

    if (!removePlan) {
      floorSign = unitTransfer?.floorPlan.signFields.map((sign: any) => {
        return {
          index: sign.index,
          key: sign.key,
          pageNumber: sign.pageNumber + selectedUnitTransferMerge.totalPages!,
          x: sign.x,
          y: sign.y,
        };
      });
    }

    let mergeTemplateSign: any;

    if (selectedMergeTemplates.length > 0) {
      mergeTemplateSign = await Promise.all(
        selectedMergeTemplates.map(async (merges: any, index: number) => {
          return Promise.all(
            merges.signFields.map((sign: any) => {
              return {
                index: sign.index,
                key: sign.key,
                pageNumber: sign.pageNumber + selectedUnitTransferMerge.totalPages! + index + 1,
                x: sign.x,
                y: sign.y,
              };
            })
          );
        })
      );
      mergeTemplateSign = [].concat.apply([], mergeTemplateSign);
    }

    const args = {
      status: 'Prepared',
      name: selectedUnitTransferMerge.name,
      project: project._id,
      deal: filteredDeal._id,
      signFields: selectedMergeTemplates.length > 0 ? [...sign, ...floorSign, ...mergeTemplateSign] : [...sign, ...floorSign],
      // signers,
      type: 'UnitTransfer',
      isAPS: false,
    };

    let newDeposit = pendingDeposits.map((pendingDeposits: IDealDeposit) => {
      return {
        accountNumber: pendingDeposits.accountNumber,
        amount: pendingDeposits.amount,
        deal: filteredDeal._id,
        dueDate: pendingDeposits.dueDate,
        name: pendingDeposits.name,
      };
    });

    let newOptions = pendingOptions.map((pendingOption: any) => {
      return {
        ...pendingOption,
        purchaseAmount: parseFloat(pendingOption.purchaseAmount),
      };
    });

    setPendingOptions(newOptions);

    if (!currentDeposits) {
      await createDeposits({ variables: { records: newDeposit } });
    }
    await createAmendment({ variables: { record: args, file: pdf } });
    await updateUnitTransfer({
      variables: {
        _id: unitTransfer._id,
        record: {
          status: 'UT',
          history: [
            ...unit.history.map((history: IUnitHistory) => {
              return {
                ...history,
                user: history.user ? history.user._id : null,
              };
            }),
            {
              type: 'Unit Transfer',
              description: `Purchaser ${filteredDeal.purchasers[0].firstName} ${filteredDeal.purchasers[0].lastName} from suite ${unit.suite} would like to unit transfer`,
              timestamp: new Date(),
              user: user._id,
            },
          ],
        },
      },
    });
    await updateUnitTransfer({
      variables: {
        _id: unit._id,
        record: {
          history: [
            ...unit.history.map((history: IUnitHistory) => {
              return {
                ...history,
                user: history.user ? history.user._id : null,
              };
            }),
            {
              type: 'Unit Transfer',
              description: `Purchaser ${filteredDeal.purchasers[0].firstName} ${filteredDeal.purchasers[0].lastName} Unit transfer amendment to suite ${unitTransfer.suite}`,
              timestamp: new Date(),
              user: user._id,
            },
          ],
        },
      },
    });
  };

  const totalOptionsPrice = () => {
    let totalOptions = filteredDeal.pendingUnit.basePrice;
    if (filteredDeal.pendingOptions.length > 0) {
      totalOptions = filteredDeal.pendingOptions.reduce((a: any, b: any) => {
        return a + b.amount;
      }, filteredDeal.pendingUnit.basePrice);
    } else if (pendingOptions.length > 0) {
      totalOptions = pendingOptions.reduce((a: any, b: any) => {
        return a + b.amount;
      }, filteredDeal.pendingUnit.basePrice);
    }
    return totalOptions;
  };

  const handleCurrentDeposits = () => {
    if (!currentDeposits) {
      setPendingDeposits(filteredDeal.deposit);
      setCurrentDeposits(!currentDeposits);
    } else {
      setPendingDeposits([]);
      setCurrentDeposits(!currentDeposits);
    }
  };

  const rentalCalculation = () => {
    if (pendingOptions.length) {
      let totalRental = pendingOptions.reduce((a: any, b: any) => {
        return a + b.rental * b.purchaseAmount;
      }, 0);
      return totalRental;
    } else return 0;
  };

  return (
    <div>
      <div>
        <Box sx={{ mb: 3, mt: 1 }}>
          Please note that the deal will still be able to be viewed under this unit until the unit transfer has been executed. You will only
          be able to a transfer to a unit that is available. Please confirm with management to proceed.
        </Box>
        {documents.find(
          (document: IDocuments) =>
            (document.type === 'UnitTransfer' && document.status !== 'Completed') ||
            (document.type === 'Options' && document.status !== 'Completed')
        ) ? (
          <div>
            <strong>
              There is currently a Unit Transfer/Deposit Amendment active. Please void/delete it to create a new Unit Transfer Amendment
            </strong>
            {filteredDeal.pendingOptions.length > 0 && document === 'UnitTransfer' ? (
              <div>
                <h3
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <em>Pending Options</em>
                </h3>
                <p>
                  <em>
                    These options are currently pending until the Unit Transfer Amendment has been executed. After the amendment has been
                    executed, the unit transfer will be complete.
                  </em>
                </p>
                <p>
                  <em>
                    WARNING: If the unit price for the new unit has been changed and the amendment has been sent with the old price, you
                    will have to void the amendment and send a new one with the new price.
                  </em>
                </p>
                <Box sx={{ mt: 3 }}>
                  <div>
                    Unit Transfer Suite: <strong>{filteredDeal.pendingUnit.suite}</strong>
                  </div>
                  <div>
                    Unit Transfer Base Price: <strong>{numToCurrency.format(filteredDeal.pendingUnit.basePrice)}</strong>
                  </div>
                  <div>
                    Total Price with Pending Options: <strong>{numToCurrency.format(totalOptionsPrice())}</strong>
                  </div>
                </Box>
                <DealOptions options={filteredDeal.pendingOptions} disabled={true} />
              </div>
            ) : null}
          </div>
        ) : (
          <form id="unit-transfer" onSubmit={(e: any) => handleUnitTransfer(e)}>
            <Dropdown
              title={'Select a Unit to Transfer to'}
              menuList={availableSuiteNames()}
              name={'unitTransfer'}
              handleSelect={(event: any) => handleUnitDropdown(event)}
              value={unitTransfer ? unitTransfer.suite : ''}
            />
            {unitTransfer ? (
              <div>
                <Box sx={{ mt: 3 }}>
                  <div>
                    Unit Price: <strong>{numToCurrency.format(unitTransfer.basePrice)}</strong>
                  </div>
                  <div>
                    Total Price: <strong>{numToCurrency.format(unitTransferTotal)}</strong>
                  </div>
                  <div>
                    Model: <strong>{unitTransfer.modelType}</strong>
                  </div>
                  <div>
                    Unit Type: <strong>{unitTransfer.unitType}</strong>
                  </div>
                </Box>
                <Grid sx={{ mt: 3 }} container spacing={2}>
                  {project.options.map((option: IOption, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <Grid
                          sx={{
                            alignSelf: 'center',
                            fontSize: '20px',
                          }}
                          item
                          xs={12}
                          sm={3}
                        >
                          <div>
                            <strong>{option.name}</strong>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <Dropdown
                            title={`Number of ${option.name} (${option.totalAvailable - optionsLeft(deals, option.name)} / ${
                              option.totalAvailable
                            })`}
                            menuList={['0', '1', '2', '3']}
                            name={'purchaseAmount'}
                            handleSelect={(e: any) => handleOptionDropdown(e, index, option.name)}
                            value={optionValue(option.name, 'purchaseAmount', index)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextInput
                            title={`${option.name} Total Amount`}
                            type="number"
                            id={'totalAmount'}
                            name={'totalAmount'}
                            handleTextInput={(e: any) => handleOptionInput(e, option.name)}
                            value={optionValue(option.name, 'amount', index)}
                            adornment={'$'}
                            required={true}
                          />
                        </Grid>
                      </React.Fragment>
                    );
                  })}
                  {unitTransfer.hasOwnProperty('rental') && unitTransfer.rental !== null ? (
                    <>
                      <Grid
                        sx={{
                          alignSelf: 'center',
                          fontSize: '20px',
                        }}
                        item
                        xs={12}
                        sm={3}
                      >
                        <div>
                          <strong>Rental</strong>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <TextInput
                          title={`Total Rental Guarantee Amount`}
                          type="number"
                          id={'rental'}
                          handleTextInput={(e: any) => {
                            setRental(e.target.value);
                          }}
                          helperText={
                            rental &&
                            pendingOptions.length &&
                            rental ===
                              pendingOptions.reduce((a: any, b: any) => {
                                return a + b.rental * b.purchaseAmount;
                              }, unitTransfer.rental)
                              ? `This Rental Guarantee has increase by ${numToCurrency.format(rentalCalculation())}`
                              : ''
                          }
                          value={rental}
                          min={'0'}
                          adornment={'$'}
                          required={false}
                        />
                      </Grid>
                    </>
                  ) : null}
                </Grid>

                <Box>
                  <FormControlLabel
                    style={{
                      marginTop: '10px',
                      marginBottom: '15px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    key={`currentDeposit`}
                    control={<Checkbox checked={currentDeposits} onChange={() => handleCurrentDeposits()} />}
                    label={'Keep Current Deposits'}
                  />
                </Box>
                {!currentDeposits ? (
                  <>
                    <Box sx={{ mt: 2 }}>
                      <Dropdown
                        title={'Deposit Type'}
                        id={'depositType'}
                        menuList={depositList()}
                        name={'depositType'}
                        handleSelect={(event: any) => setSelectedDeposit(event.target.value)}
                        value={selectedDeposit}
                      />
                    </Box>
                    <DealDeposits
                      deposit={pendingDeposits}
                      basePrice={unitTransferTotal}
                      handleDepositsArray={handlePendingDepositsArray}
                      disable={false}
                    />
                  </>
                ) : null}
                <h2>Select a Unit Transfer Amendment</h2>
                <Dropdown
                  title={'Unit Transfer Amendment'}
                  menuList={merges.filter((mergeFields: IMerge) => mergeFields.type === 'UnitTransfer').map((merge: any) => merge.name)}
                  name={'options'}
                  handleSelect={(e: any) => handleUnitTransferInput(e)}
                  value={selectedUnitTransferMerge ? selectedUnitTransferMerge.name : ''}
                />
                <Box>
                  <FormControlLabel
                    style={{
                      marginTop: '10px',
                      marginBottom: '15px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    key={`removePlan`}
                    control={<Checkbox checked={removePlan} onChange={() => setRemovePlan(!removePlan)} />}
                    label={'Remove Floorplan from Amendment'}
                  />
                </Box>
                <h2>Attach Additional Amendments/Schedules</h2>
                <Dropdown
                  title={'Attach Amendments or Schedules'}
                  menuList={mergeNames}
                  name={'options'}
                  handleSelect={(e: any) => handleDropdownInput(e)}
                  value={''}
                />
                <Box
                  sx={{
                    display: 'flex',
                    margin: '10px 0',
                  }}
                >
                  {selectedMergeTemplates.map((merge: IMerge, index: number) => {
                    if (merge.name === 'Floorplan') {
                      return null;
                    } else {
                      return (
                        <Box
                          key={index}
                          sx={{
                            padding: '10px',
                            border: '1px solid #00142a',
                            borderRadius: '8px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            marginRight: '10px',
                          }}
                        >
                          <span>{merge.name}</span>
                          <CloseIcon
                            sx={{
                              cursor: 'pointer',
                              marginLeft: '5px',
                            }}
                            color="secondary"
                            onClick={() => handleDeleteMergeTemplate(merge.name)}
                          />
                        </Box>
                      );
                    }
                  })}
                </Box>
                <Box sx={{ mt: 3 }}>
                  <Button form="unit-transfer" type="submit" color="primary" variant="contained">
                    Create Unit Transfer Amendment
                  </Button>
                </Box>
              </div>
            ) : null}
          </form>
        )}
      </div>
    </div>
  );
};

interface ChildProps {
  handleDepositSubmit: any;
  handleOptions: any;
  handleOptionDropdown: any;
  handleOptionInput: any;
  optionValue: any;
  depositList: any;
  createAmendment: any;
  createDeposits: any;
  selectedDeposit: any;
  setSelectedDeposit: any;
  pendingOptions: any;
  setPendingOptions: any;
  unitTransferTotal: any;
  setUnitTransferTotal: any;
  unitTransfer: any;
  setUnitTransfer: any;
}

const UPDATEUNIT = gql`
  mutation unitUpdateById($_id: MongoID!, $record: UpdateByIdUnitInput!) {
    unitUpdateById(_id: $_id, record: $record) {
      record {
        _id
        suite
        tier
        modelType
        unitType
        bathroom
        size
        outdoorType
        basePrice
        level
        exposure
        outdoorSize
        col
        row
        status
        getUrl
        tempAllocation
        history {
          type
          description
          timestamp
          user {
            _id
            fullName
          }
          _id
        }
        stackRow
      }
    }
  }
`;

const UNITS = gql`
  query unitMany($filter: FilterFindManyUnitInput) {
    unitMany(filter: $filter, limit: 10000) {
      _id
      suite
      unit
      level
      tier
      modelType
      unitType
      bathroom
      size
      basePrice
      col
      row
      rental
      status
      getUrl
      floorPlan {
        _id
        mergeFields {
          key
          index
          pageNumber
          x
          y
          fontSize
          format
          wrap
        }
        signFields {
          key
          index
          pageNumber
          x
          y
        }
      }
      history {
        type
        description
        timestamp
        _id
        user {
          _id
          fullName
        }
      }
    }
  }
`;

const MERGETEMPLATES = gql`
  query mergeTemplateMany($filter: FilterFindManyMergeTemplateInput) {
    mergeTemplateMany(filter: $filter) {
      _id
      project {
        _id
      }
      name
      mergeFields {
        key
        index
        pageNumber
        x
        y
        fontSize
        format
        wrap
      }
      signFields {
        key
        index
        pageNumber
        x
        y
        name
      }
      default
      totalPages
      type
      getUrl
      putUrl
    }
  }
`;

export default UnitTransfer;
