import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Fade,
  Typography,
  Button,
  Drawer,
  Checkbox,
  FormControlLabel,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NotesIcon from '@mui/icons-material/Notes';
import { useMutation, gql, useQuery, useLazyQuery } from '@apollo/client';
import { ICollection, IUpgrade, IUpgradeTemplate, IDecor } from '../../../types/colourSelection';
import CollectionCard from './CollectionCard';
import UpgradeCard from './UpgradeCard';
import ColourSelectionDrawer from './ColourSelectionDrawer';
import { Flex, FlexBetween } from '../../../commonStyles';
import { useAppDispatch } from '../../../app/hooks';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../features/snackbar/snackbarSlice';
import LoadingWrapper from '../../common/LoadingWrapper';
import { IUser, IUserArray } from '../../../types/user';
import { GlobalModal } from '../../../features/modal/Modal';
import { handleModal } from '../../../features/modal/modalSlice';
import Dropdown from '../../common/formControls/Dropdown';
import { mainCategories as categories } from '../../../utils/Constants';
import { createDecorTablePdf, uniqueArrayOfObjects, createPdf, combinePdfs, camelToTitle, numToCurrency } from '../../../utils/Functions';
import { IMerge } from '../../../types/merge';
import { IDocuments } from '../../../types/unit';
import { IDecorModels, IProject } from '../../../types/project';
import CommentsTable from '../../common/CommentsTable';
import { IComment, ICommentArray } from '../../../types/comment';
import CustomUpgrade from './CustomUpgrade';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../features/auth/authSlice';
import { useCreateActivity } from '../../../features/activity/activityHooks';

const ColourSelection = (props: ChildProps) => {
  const { client, unit, filteredDeal, project, updateDealDocuments, documents, setDocuments } = props;
  const user = useSelector(selectUser);
  const storeDispatch = useAppDispatch();
  const createActivity = useCreateActivity();

  const [view, setView] = useState<string>('collection');
  const [collections, setCollections] = useState<ICollection[]>([]);
  const [allUpgradeTemplates, setAllUpgradeTemplates] = useState<IUpgradeTemplate[]>([]);
  const [upgradeTemplates, setUpgradeTemplates] = useState<IUpgradeTemplate[]>([]);
  const [selectedCollection, setSelectedCollection] = useState<ICollection | null>(null);
  const [mainCategories, setMainCategories] = useState<string[]>([]);
  const [selectedMainCategory, setSelectedMainCategory] = useState<string>(mainCategories.length ? mainCategories[0] : '');
  const [subCategories, setSubCategories] = useState<ISubCategory[]>([]);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>('');
  const [users, setUsers] = useState<IUser[]>([]);
  const [mergeTemplates, setMergeTemplates] = useState<IMerge[]>([]);
  const [selectedMerge, setSelectedMerge] = useState<IMerge | null>(null);
  const [amendmentUpgrades, setAmendmentUpgrades] = useState<IUpgrade[]>([]);
  const [comments, setComments] = useState<IComment[]>([]);
  const [showSelection, setShowSelection] = useState<boolean>(false);
  const [upgradeSetOne, setUpgradeSetOne] = useState<IDecor>({
    date: null,
    salesRep: null,
    upgradeSet: [],
    collectionTemplate: null,
  });
  const [upgradeSetTwo, setUpgradeSetTwo] = useState<IDecor>({
    date: null,
    salesRep: null,
    upgradeSet: [],
    collectionTemplate: null,
  });
  const [upgradeSetThree, setUpgradeSetThree] = useState<IDecor>({
    date: null,
    salesRep: null,
    upgradeSet: [],
    collectionTemplate: null,
  });
  const [selectedUpgrades, setSelectedUpgrades] = useState<string>(
    filteredDeal ? (filteredDeal.upgradeSetThree ? 'upgradeSetThree' : filteredDeal.upgradeSetTwo ? 'upgradeSetTwo' : 'upgradeSetOne') : ''
  );

  const { loading } = useQuery(COLLECTIONS, {
    variables: { project: project._id, unit: unit._id },
    onCompleted: (data) => {
      let upgradeSession = selectedUpgrades;
      setAllUpgradeTemplates(data.getCollections.upgrades);
      if (filteredDeal && filteredDeal.upgradeSetOne) {
        setUpgradeSetOne(filteredDeal.upgradeSetOne);
      } else if (unit.upgradeSetOne) {
        setUpgradeSetOne(unit.upgradeSetOne);
        setSelectedUpgrades('upgradeSetOne');
        upgradeSession = 'upgradeSetOne';
      }
      if (filteredDeal && filteredDeal.upgradeSetTwo) {
        setUpgradeSetTwo(filteredDeal.upgradeSetTwo);
      } else if (unit.upgradeSetTwo) {
        setUpgradeSetTwo(unit.upgradeSetTwo);
        setSelectedUpgrades('upgradeSetTwo');
        upgradeSession = 'upgradeSetTwo';
      }
      if (filteredDeal && filteredDeal.upgradeSetThree) {
        setUpgradeSetThree(filteredDeal.upgradeSetThree);
      } else if (unit.upgradeSetThree) {
        setUpgradeSetThree(unit.upgradeSetThree);
        setSelectedUpgrades('upgradeSetThree');
        upgradeSession = 'upgradeSetThree';
      }
      let filteredUpgradeTemplates = data.getCollections.upgrades.filter((upgrade: IUpgradeTemplate) =>
        upgrade.sessions.includes(camelToTitle(upgradeSession))
      );
      setUpgradeTemplates(filteredUpgradeTemplates);
      setCollections(data.getCollections.collections);
      let collectionOne = data.getCollections.collections.find(
        (collection: ICollection) => collection.session === camelToTitle(collection.session)
      );
      if (!data.getCollections.collections.length || !collectionOne) {
        handleCollection(null, filteredUpgradeTemplates, false);
      }
      if (filteredDeal && filteredDeal.upgradeSetOne.collectionTemplate) {
        handleCollection(filteredDeal.collectionTemplate, filteredUpgradeTemplates, false);
      }
    },
  });

  useQuery(MERGETEMPLATES, {
    variables: { filter: { project: project._id, type: 'Decor' } },
    onCompleted: (data) => {
      setMergeTemplates(data.mergeTemplateMany);
    },
  });

  useQuery<IUserArray>(GETSALES, {
    variables: { filter: { OR: [{ type: 'Sales' }, { type: 'Manager' }, { type: 'Admin' }], locked: false }, sort: 'NAME' },
    onCompleted: (data) => {
      setUsers(data.userMany);
    },
  });

  const [getComments] = useLazyQuery<ICommentArray>(GETCOMMENTS, {
    variables: { filter: { deal: filteredDeal ? filteredDeal._id : null, unit: unit?._id, project: project._id, type: 'decor' } },
    onCompleted: (data) => {
      let sortedComments = [...data.commentMany].sort((a: any, b: any) => {
        if (b.pinned - a.pinned !== 0) {
          return b.pinned - a.pinned;
        } else return new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf();
      });
      setComments(sortedComments);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [saveDecor] = useMutation(SAVEDECOR, {
    onCompleted: (data) => {
      if (data.saveDecor.upgradeSetOne) {
        setUpgradeSetOne(data.saveDecor.upgradeSetOne);
        if (selectedUpgrades === 'upgradeSetOne') {
          createActivity({
            project: project._id,
            user: user._id,
            deal: filteredDeal._id,
            title: `Update Decor Upgrade Set One`,
            content: `${data.saveDecor.upgradeSetOne.upgradeSet
              .map((upgradeSet: IUpgrade) => `${upgradeSet.name} - x${upgradeSet.quantity} - ${numToCurrency.format(upgradeSet.price)}`)
              .join(', ')}. Total = ${numToCurrency.format(
              data.saveDecor.upgradeSetOne.upgradeSet.reduce((a: any, b: any) => a + b.price, 0)
            )}`,
          });
        }
      }
      if (data.saveDecor.upgradeSetTwo) {
        setUpgradeSetTwo(data.saveDecor.upgradeSetTwo);
        if (selectedUpgrades === 'upgradeSetTwo') {
          createActivity({
            project: project._id,
            user: user._id,
            deal: filteredDeal._id,
            title: `Update Decor Upgrade Set Two`,
            content: `${data.saveDecor.upgradeSetTwo.upgradeSet
              .map((upgradeSet: IUpgrade) => `${upgradeSet.name} - x${upgradeSet.quantity} - ${numToCurrency.format(upgradeSet.price)}`)
              .join(', ')}. Total = ${numToCurrency.format(
              data.saveDecor.upgradeSetTwo.upgradeSet.reduce((a: any, b: any) => a + b.price, 0)
            )}`,
          });
        }
      }
      if (data.saveDecor.upgradeSetThree) {
        setUpgradeSetThree(data.saveDecor.upgradeSetThree);
        if (selectedUpgrades === 'upgradeSetThree') {
          createActivity({
            project: project._id,
            user: user._id,
            deal: filteredDeal._id,
            title: `Update Decor Upgrade Set Three`,
            content: `${data.saveDecor.upgradeSetThree.upgradeSet
              .map((upgradeSet: IUpgrade) => `${upgradeSet.name} - x${upgradeSet.quantity} - ${numToCurrency.format(upgradeSet.price)}`)
              .join(', ')}. Total = ${numToCurrency.format(
              data.saveDecor.upgradeSetThree.upgradeSet.reduce((a: any, b: any) => a + b.price, 0)
            )}`,
          });
        }
      }
      setOpenDrawer(false);
      storeDispatch(showSuccessSnackbar('Decor Selection Saved!'));
    },
  });

  const [createDocument, { loading: createDocumentLoading }] = useMutation(CREATEDOCUMENT, {
    onCompleted: (data) => {
      let documentIds = documents.map((document: IDocuments) => {
        return document._id;
      });
      updateDealDocuments({
        variables: { _id: filteredDeal._id, record: { documents: [...documentIds, data.documentCreateOne._id] } },
      });
      setDocuments([...documents, data.documentCreateOne]);
      setSelectedMerge(null);
      storeDispatch(handleModal(false));
      setOpenDrawer(false);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const handleUpgradeSet = (event: any) => {
    if (event.target.value !== null) {
      setSelectedUpgrades(event.target.value);
      let chosenUpgrade = chosenUpgrades(event.target.value);
      let filteredUpgradeTemplates = allUpgradeTemplates.filter((upgrade: IUpgradeTemplate) => {
        if (client && chosenUpgrade.collectionTemplate && chosenUpgrade.collectionTemplate.schemes.length) {
          return (
            upgrade.sessions.includes(camelToTitle(event.target.value)) &&
            chosenUpgrade.collectionTemplate.schemes.some((r) => upgrade.schemes.includes(r))
          );
        } else {
          return upgrade.sessions.includes(camelToTitle(event.target.value));
        }
      });

      let templates = filteredUpgradeTemplates;

      let ids = new Set(templates.map((d) => d._id));
      let merged = [...templates, ...filteredUpgradeTemplates.filter((d) => !ids.has(d._id))];

      let selectedModelType = project.decorModels.find((decorModel: IDecorModels) => decorModel.modelType === unit.modelType);
      let uniqueMainCategories = [...new Set(merged.map((item) => item.mainCategory))];

      if (selectedModelType) {
        uniqueMainCategories = uniqueMainCategories.filter((mainCategory: string) => !selectedModelType?.allowed.includes(mainCategory));
      }

      let sortedMainCategory = uniqueMainCategories.sort(function (a, b) {
        return categories.indexOf(a) - categories.indexOf(b);
      });
      let subCategories = [
        ...new Set(filteredUpgradeTemplates.filter((item) => item.mainCategory === sortedMainCategory[0]).map((item) => item.subCategory)),
      ];

      let subCategoryUpgrades = subCategories.map((subCategory) => {
        return {
          subCategory: subCategory,
          upgrades: filteredUpgradeTemplates.filter(
            (upgrades: IUpgradeTemplate) => subCategory === upgrades.subCategory && sortedMainCategory[0] === upgrades.mainCategory
          ),
        };
      });

      setMainCategories(sortedMainCategory);
      setSelectedMainCategory(sortedMainCategory[0]);
      setUpgradeTemplates(filteredUpgradeTemplates);
      setSubCategories(subCategoryUpgrades);
    } else return;
  };

  useEffect(() => {
    chosenUpgrades(selectedUpgrades);
  }, [selectedUpgrades, handleUpgradeSet]);

  const chosenUpgrades = (upgrade: string) => {
    if (upgrade === 'upgradeSetOne') {
      return upgradeSetOne;
    } else if (upgrade === 'upgradeSetTwo') {
      return upgradeSetTwo;
    } else {
      return upgradeSetThree;
    }
  };

  const handleCollection = (collection: ICollection | null, upgradeTemplates: IUpgradeTemplate[] = [], update: boolean) => {
    window.scrollTo(0, 0);
    let templates: IUpgradeTemplate[] = [];
    if (collection) {
      templates = collection.upgradeTemplates;
    } else {
      templates = upgradeTemplates;
    }

    let ids = new Set(templates.map((d) => d._id));
    let merged = [...templates, ...upgradeTemplates.filter((d) => !ids.has(d._id))];
    let selectedModelType = project.decorModels.find((decorModel: IDecorModels) => decorModel.modelType === unit.modelType);

    let filteredUpgradeTemplates = merged.filter((upgrade: IUpgradeTemplate) => {
      if (collection && collection.schemes.length) {
        return (
          upgrade.sessions.includes(camelToTitle(selectedUpgrades)) &&
          collection.schemes.some((r) => upgrade.schemes.includes(r)) &&
          !selectedModelType?.allowed.includes(upgrade.mainCategory)
        );
      } else {
        return upgrade;
      }
    });

    let uniqueMainCategories = [...new Set(merged.map((item) => item.mainCategory))];

    if (selectedModelType) {
      uniqueMainCategories = uniqueMainCategories.filter((mainCategory: string) => !selectedModelType?.allowed.includes(mainCategory));
    }

    let sortedMainCategory = uniqueMainCategories.sort(function (a, b) {
      return categories.indexOf(a) - categories.indexOf(b);
    });

    let subCategories = [
      ...new Set(upgradeTemplates.filter((item) => item.mainCategory === sortedMainCategory[0]).map((item) => item.subCategory)),
    ];

    let subCategoryUpgrades = subCategories.map((subCategory) => {
      return {
        subCategory: subCategory,
        upgrades: filteredUpgradeTemplates.filter(
          (upgrades: IUpgradeTemplate) => subCategory === upgrades.subCategory && sortedMainCategory[0] === upgrades.mainCategory
        ),
      };
    });

    if (update) {
      if (collection) {
        let collectionUpgrades = collection.upgradeTemplates
          .filter((upgradeTemplate: IUpgradeTemplate) => !selectedModelType?.allowed.includes(upgradeTemplate.mainCategory))
          .map((upgradeTemplate: IUpgradeTemplate) => {
            return {
              deal: filteredDeal,
              unit: unit,
              project: project,
              upgradeTemplate: upgradeTemplate,
              price: upgradeTemplate.price,
              name: upgradeTemplate.name,
              mainCategory: upgradeTemplate.mainCategory,
              subCategory: upgradeTemplate.subCategory,
              quantity: 1,
            };
          });
        if (selectedUpgrades === 'upgradeSetOne') {
          setUpgradeSetOne({ ...upgradeSetOne, upgradeSet: collectionUpgrades, collectionTemplate: collection });
        } else if (selectedUpgrades === 'upgradeSetTwo') {
          setUpgradeSetTwo({ ...upgradeSetTwo, upgradeSet: collectionUpgrades, collectionTemplate: collection });
        } else {
          setUpgradeSetThree({ ...upgradeSetThree, upgradeSet: collectionUpgrades, collectionTemplate: collection });
        }
      }
    }

    setUpgradeTemplates(filteredUpgradeTemplates);
    setView('upgrade');
    setSelectedCollection(collection);
    setSubCategories(subCategoryUpgrades);
    setMainCategories(sortedMainCategory);
    setSelectedMainCategory(sortedMainCategory[0]);
  };

  const handleMainCategory = (event: any) => {
    if (event.target.value !== null) {
      setSelectedMainCategory(event.target.value);
    } else return;

    let subCategories = [
      ...new Set(upgradeTemplates.filter((item) => item.mainCategory === event.target.value).map((item) => item.subCategory)),
    ];

    let subCategoryUpgrades = subCategories.map((subCategory) => {
      return {
        subCategory: subCategory,
        upgrades: upgradeTemplates.filter(
          (upgrades: IUpgradeTemplate) => subCategory === upgrades.subCategory && event.target.value === upgrades.mainCategory
        ),
      };
    });

    setSubCategories(subCategoryUpgrades);
  };

  const toggleDrawer = (boolean: boolean) => {
    setOpenDrawer(boolean);
  };

  const addUpgrade = (upgradeTemplate: IUpgradeTemplate, selectedUpgrade: IUpgrade | null) => {
    let upgrades = chosenUpgrades(selectedUpgrades)!;
    let addQuantity: any;

    if (!upgradeTemplate && selectedUpgrade) {
      addQuantity = upgrades.upgradeSet.find(
        (upgrade: IUpgrade) =>
          selectedUpgrade.name === upgrade.name &&
          upgrade.mainCategory === selectedUpgrade.mainCategory &&
          upgrade.subCategory === selectedUpgrade.subCategory
      );
    } else {
      addQuantity = upgrades.upgradeSet.find(
        (upgrade: IUpgrade) =>
          upgradeTemplate.name === upgrade.name &&
          upgrade.mainCategory === upgradeTemplate.mainCategory &&
          upgrade.subCategory === upgradeTemplate.subCategory
      );
    }
    if (!addQuantity) {
      let addedUpgrade = {
        deal: filteredDeal,
        unit: unit,
        project: project,
        quantity: 1,
        upgradeTemplate: upgradeTemplate,
        price: upgradeTemplate ? (upgradeTemplate.freeUpgrades.includes(unit.suite) ? 0 : upgradeTemplate.price) : selectedUpgrade?.price!,
        name: upgradeTemplate.name,
        mainCategory: upgradeTemplate.mainCategory,
        subCategory: upgradeTemplate.subCategory,
      };

      if (selectedUpgrades === 'upgradeSetOne') {
        setUpgradeSetOne({ ...upgrades, upgradeSet: [...upgrades.upgradeSet, addedUpgrade] });
      } else if (selectedUpgrades === 'upgradeSetTwo') {
        setUpgradeSetTwo({ ...upgrades, upgradeSet: [...upgrades.upgradeSet, addedUpgrade] });
      } else {
        setUpgradeSetThree({ ...upgrades, upgradeSet: [...upgrades.upgradeSet, addedUpgrade] });
      }
    } else {
      let updateQuantity = upgrades.upgradeSet.map((upgrade: IUpgrade) => {
        if (!upgradeTemplate && selectedUpgrade) {
          if (
            upgrade.name === addQuantity.name &&
            upgrade.mainCategory === selectedUpgrade?.mainCategory! &&
            upgrade.subCategory === selectedUpgrade?.subCategory!
          ) {
            return {
              ...upgrade,
              quantity: addQuantity.quantity + 1,
              price: (selectedUpgrade.price / addQuantity.quantity) * (addQuantity.quantity + 1),
            };
          } else return upgrade;
        } else if (
          upgrade.name === addQuantity.name &&
          upgrade.mainCategory === upgradeTemplate.mainCategory &&
          upgrade.subCategory === upgradeTemplate.subCategory
        ) {
          return {
            ...upgrade,
            quantity: addQuantity.quantity + 1,
            price: upgradeTemplate.price * (addQuantity.quantity + 1),
          };
        } else return upgrade;
      });

      if (selectedUpgrades === 'upgradeSetOne') {
        setUpgradeSetOne({ ...upgrades, upgradeSet: updateQuantity });
      } else if (selectedUpgrades === 'upgradeSetTwo') {
        setUpgradeSetTwo({ ...upgrades, upgradeSet: updateQuantity });
      } else {
        setUpgradeSetThree({ ...upgrades, upgradeSet: updateQuantity });
      }
    }
  };

  const deleteUpgrade = (upgradeTemplate: IUpgradeTemplate, selectedUpgrade: any) => {
    let upgrades = chosenUpgrades(selectedUpgrades)!;
    let deleteQuantity: any;

    if (!upgradeTemplate && selectedUpgrade) {
      deleteQuantity = upgrades.upgradeSet.find(
        (upgrade: IUpgrade) =>
          selectedUpgrade.name === upgrade.name &&
          upgrade.mainCategory === selectedUpgrade.mainCategory &&
          upgrade.subCategory === selectedUpgrade.subCategory
      );
    } else {
      deleteQuantity = upgrades.upgradeSet.find(
        (upgrade: IUpgrade) =>
          upgradeTemplate.name === upgrade.name &&
          upgrade.mainCategory === upgradeTemplate.mainCategory &&
          upgrade.subCategory === upgradeTemplate.subCategory
      );
    }

    if (deleteQuantity && deleteQuantity.quantity <= 1) {
      if (selectedUpgrades === 'upgradeSetOne') {
        setUpgradeSetOne({
          ...upgradeSetOne,
          upgradeSet: upgradeTemplate
            ? upgradeSetOne.upgradeSet.filter((upgrade: IUpgrade) => upgrade.upgradeTemplate?._id !== upgradeTemplate._id)
            : upgradeSetOne.upgradeSet.filter((upgrade: any) => upgrade._id !== selectedUpgrade?.id!),
        });
      } else if (selectedUpgrades === 'upgradeSetTwo') {
        setUpgradeSetTwo({
          ...upgradeSetTwo,
          upgradeSet: upgradeTemplate
            ? upgradeSetTwo.upgradeSet.filter((upgrade: IUpgrade) => upgrade.upgradeTemplate?._id !== upgradeTemplate._id)
            : upgradeSetTwo.upgradeSet.filter((upgrade: any) => upgrade._id !== selectedUpgrade?.id!),
        });
      } else {
        setUpgradeSetThree({
          ...upgradeSetThree,
          upgradeSet: upgradeTemplate
            ? upgradeSetThree.upgradeSet.filter((upgrade: IUpgrade) => upgrade.upgradeTemplate?._id !== upgradeTemplate._id)
            : upgradeSetThree.upgradeSet.filter((upgrade: any) => upgrade._id !== selectedUpgrade?.id!),
        });
      }
    } else {
      let updateQuantity = upgrades.upgradeSet.map((upgrade: IUpgrade) => {
        if (!upgradeTemplate && selectedUpgrade) {
          if (
            upgrade.name === deleteQuantity.name &&
            upgrade.mainCategory === selectedUpgrade?.mainCategory! &&
            upgrade.subCategory === selectedUpgrade?.subCategory!
          ) {
            return {
              ...upgrade,
              quantity: deleteQuantity.quantity - 1,
              price: (selectedUpgrade.price / deleteQuantity.quantity) * (deleteQuantity.quantity - 1),
            };
          } else return upgrade;
        } else if (
          upgrade.name === deleteQuantity.name &&
          upgrade.mainCategory === upgradeTemplate.mainCategory &&
          upgrade.subCategory === upgradeTemplate.subCategory
        ) {
          return {
            ...upgrade,
            quantity: deleteQuantity.quantity - 1,
            price: upgradeTemplate.price * deleteQuantity.quantity,
          };
        } else return upgrade;
      });

      if (selectedUpgrades === 'upgradeSetOne') {
        setUpgradeSetOne({ ...upgrades, upgradeSet: updateQuantity });
      } else if (selectedUpgrades === 'upgradeSetTwo') {
        setUpgradeSetTwo({ ...upgrades, upgradeSet: updateQuantity });
      } else {
        setUpgradeSetThree({ ...upgrades, upgradeSet: updateQuantity });
      }
    }
  };

  const handlePrice = (e: any, id: string, mainCategory: any, subCategory: any) => {
    if (!e.target) return;
    let upgrades = chosenUpgrades(selectedUpgrades).upgradeSet.map((upgrade: IUpgrade, index: number) => {
      if (mainCategory && subCategory) {
        if (upgrade.upgradeTemplate) {
          if (
            upgrade.upgradeTemplate?.name === id &&
            upgrade.upgradeTemplate?.mainCategory === mainCategory &&
            upgrade.upgradeTemplate?.subCategory === subCategory
          ) {
            return {
              ...upgrade,
              price: !e.target.value ? 0 : parseInt(e.target.value, 10),
            };
          } else return upgrade;
        } else {
          if (upgrade.name === id && upgrade.mainCategory === mainCategory && upgrade.subCategory === subCategory) {
            return {
              ...upgrade,
              price: !e.target.value ? 0 : parseInt(e.target.value, 10),
            };
          } else return upgrade;
        }
      } else {
        if (upgrade.upgradeTemplate) {
          if (upgrade.upgradeTemplate?.name === id) {
            return {
              ...upgrade,
              price: !e.target.value ? 0 : parseInt(e.target.value, 10),
            };
          } else return upgrade;
        } else {
          if (upgrade.name === id) {
            return {
              ...upgrade,
              price: !e.target.value ? 0 : parseInt(e.target.value, 10),
            };
          } else return upgrade;
        }
      }
    });

    if (selectedUpgrades === 'upgradeSetOne') {
      setUpgradeSetOne({
        ...upgradeSetOne,
        upgradeSet: upgrades,
      });
    } else if (selectedUpgrades === 'upgradeSetTwo') {
      setUpgradeSetTwo({
        ...upgradeSetTwo,
        upgradeSet: upgrades,
      });
    } else {
      setUpgradeSetThree({
        ...upgradeSetThree,
        upgradeSet: upgrades,
      });
    }
  };

  const handleSave = () => {
    let chosenUpgrade = chosenUpgrades(selectedUpgrades);
    if (!chosenUpgrade.upgradeSet.length) return storeDispatch(showErrorSnackbar('Need at least one upgrade'));
    if (!chosenUpgrade.salesRep) return storeDispatch(showErrorSnackbar('Sales Rep is not selected'));

    let newObject = chosenUpgrades(selectedUpgrades).upgradeSet.map((upgrade: IUpgrade) => {
      return {
        ...upgrade,
        project: project._id,
        deal: filteredDeal && filteredDeal._id ? filteredDeal._id : null,
        unit: unit._id,
        upgradeTemplate: upgrade.upgradeTemplate?._id,
      };
    });

    let decorObject = {
      date: chosenUpgrade.date,
      salesRep: chosenUpgrade.salesRep._id,
      upgradeSet: newObject,
      collectionTemplate: chosenUpgrade.collectionTemplate ? chosenUpgrade.collectionTemplate._id : null,
    };

    saveDecor({
      variables: {
        deal: filteredDeal && filteredDeal._id ? filteredDeal._id : null,
        unit: unit._id,
        upgrades: decorObject,
        type: selectedUpgrades,
      },
    });
  };

  const handleNewUpgradeSet = () => {
    let upgradeSet = 'upgradeSetTwo';
    if (upgradeSetTwo.upgradeSet.length) {
      upgradeSet = 'upgradeSetThree';
      setSelectedUpgrades('upgradeSetThree');
    } else {
      upgradeSet = 'upgradeSetTwo';
      setSelectedUpgrades('upgradeSetTwo');
    }

    let filteredUpgradeTemplates = allUpgradeTemplates.filter((upgrade: IUpgradeTemplate) => {
      if (client && selectedCollection && selectedCollection.schemes.length) {
        return upgrade.sessions.includes(camelToTitle(upgradeSet)) && selectedCollection.schemes.some((r) => upgrade.schemes.includes(r));
      } else {
        return upgrade.sessions.includes(camelToTitle(upgradeSet));
      }
    });

    let templates = filteredUpgradeTemplates;

    let ids = new Set(templates.map((d) => d._id));
    let merged = [...templates, ...filteredUpgradeTemplates.filter((d) => !ids.has(d._id))];

    let selectedModelType = project.decorModels.find((decorModel: IDecorModels) => decorModel.modelType === unit.modelType);
    let uniqueMainCategories = [...new Set(merged.map((item) => item.mainCategory))];

    if (selectedModelType) {
      uniqueMainCategories = uniqueMainCategories.filter((mainCategory: string) => !selectedModelType?.allowed.includes(mainCategory));
    }

    let sortedMainCategory = uniqueMainCategories.sort(function (a, b) {
      return categories.indexOf(a) - categories.indexOf(b);
    });

    let subCategories = [
      ...new Set(filteredUpgradeTemplates.filter((item) => item.mainCategory === sortedMainCategory[0]).map((item) => item.subCategory)),
    ];

    let subCategoryUpgrades = subCategories.map((subCategory) => {
      return {
        subCategory: subCategory,
        upgrades: filteredUpgradeTemplates.filter(
          (upgrades: IUpgradeTemplate) => subCategory === upgrades.subCategory && sortedMainCategory[0] === upgrades.mainCategory
        ),
      };
    });

    let selectCollection = collections.find((collection: ICollection) => collection.session === camelToTitle(upgradeSet));
    if (selectCollection) {
      setView('collection');
    }

    setMainCategories(sortedMainCategory);
    setSelectedMainCategory(sortedMainCategory[0]);
    setSubCategories(subCategoryUpgrades);

    setUpgradeTemplates(filteredUpgradeTemplates);
  };

  const handleSalesDropdown = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    let selectedUser = users.find((user: IUser) => user.fullName === event.target.value);
    if (selectedUser) {
      if (selectedUpgrades === 'upgradeSetOne') {
        setUpgradeSetOne({
          ...upgradeSetOne,
          salesRep: selectedUser,
        });
      } else if (selectedUpgrades === 'upgradeSetTwo') {
        setUpgradeSetTwo({
          ...upgradeSetTwo,
          salesRep: selectedUser,
        });
      } else {
        setUpgradeSetThree({
          ...upgradeSetThree,
          salesRep: selectedUser,
        });
      }
    }
  };

  const openModal = (type: string) => {
    if (filteredDeal && !filteredDeal._id && type === 'amendment') return storeDispatch(showErrorSnackbar('Cannot Create Amendment'));
    setModalType(type);
    setAmendmentUpgrades(chosenUpgrades(selectedUpgrades).upgradeSet);
    storeDispatch(handleModal(true));
  };

  const handleMergeDropdown = (e: any) => {
    let merge = mergeTemplates.find((mergeTemplate: IMerge) => mergeTemplate.name === e.target.value);
    if (merge) {
      setSelectedMerge(merge);
    }
  };

  const createDecorDocument = async (type: string) => {
    if (filteredDeal && !filteredDeal._id && type === 'amendment') return storeDispatch(showErrorSnackbar('Cannot Create Amendment'));
    if (!selectedMerge && type === 'amendment') return storeDispatch(showErrorSnackbar('Decor Template is not selected'));
    let chosenUpgrade = chosenUpgrades(selectedUpgrades);
    if (!chosenUpgrade.upgradeSet.length) return storeDispatch(showErrorSnackbar('Need at least one upgrade'));
    if (!chosenUpgrade.salesRep) return storeDispatch(showErrorSnackbar('Decor Specialist is not selected'));
    let upgrades: IDecor | null = null;
    if (selectedUpgrades === 'upgradeSetOne') {
      upgrades = upgradeSetOne;
    } else if (selectedUpgrades === 'upgradeSetTwo') {
      upgrades = upgradeSetTwo;
    } else if (selectedUpgrades === 'upgradeSetThree') {
      upgrades = upgradeSetThree;
    }

    let upgradeSet: any[] = amendmentUpgrades;

    if (type === 'preview' || type === 'previewPrice') {
      upgradeSet = upgrades?.upgradeSet!;
    }

    if (upgrades) {
      let uniqueMainCategories = [
        ...new Set(
          upgrades.upgradeSet.map((item) => {
            return {
              mainCategory: item.mainCategory,
              subCategory: item.subCategory,
            };
          })
        ),
      ].filter((upgradeTemplate: any) => {
        if (upgradeSet.some((upgrade: IUpgrade) => upgrade.mainCategory === upgradeTemplate.mainCategory)) return upgradeTemplate;
      });

      const uniqueCategoryArray = uniqueArrayOfObjects(uniqueMainCategories, ['mainCategory', 'subCategory']);

      let mergedCategoryArray = [...new Set(uniqueCategoryArray.map((item) => item.mainCategory))];

      let selectedUpgradeTemplates = chosenUpgrade.upgradeSet.map((upgrade: any) => upgrade);

      let categoryData = mergedCategoryArray.map((category: any) => {
        let filtered = selectedUpgradeTemplates.filter(
          (upgradeTemplate: IUpgradeTemplate) => upgradeTemplate && upgradeTemplate.mainCategory === category
        );
        let uniqueSubCategories = [...new Set(filtered.map((item) => item.subCategory))];
        let uniqueData = uniqueSubCategories.map((subCategories) => {
          let selectedUpgrade: IUpgrade[] | undefined = upgradeSet.filter(
            (upgrade: IUpgrade) => upgrade.subCategory === subCategories && upgrade.mainCategory === category
          );
          let selectedSubCategory = selectedUpgradeTemplates.find(
            (upgradeTemplate: IUpgradeTemplate) =>
              upgradeTemplate.subCategory === subCategories && upgradeTemplate.mainCategory === category
          );

          if (selectedUpgrade?.length) {
            let allUpgrades = selectedUpgrade.map((upgrade: IUpgrade) => {
              if (upgrade) {
                return {
                  selected: 'Y',
                  component: subCategories,
                  selection: selectedSubCategory.upgradeTemplate ? selectedSubCategory?.upgradeTemplate.upgradeType : 'UPG1',
                  quantity: upgrade.quantity,
                  description: upgrade.name,
                  price: upgrade.price ? upgrade.price : 'Included',
                };
              } else
                return {
                  selected: '',
                  component: subCategories,
                  selection: '',
                  quantity: '',
                  description: '',
                  price: '',
                };
            });

            return allUpgrades.flat();
          } else
            return {
              selected: '',
              component: subCategories,
              selection: '',
              quantity: '',
              description: '',
              price: '',
            };
        });

        let upgradeList = uniqueData.flat();

        if (showSelection) {
          upgradeList = uniqueData
            .flat()
            .filter((upgrade: any) => upgrade.selected)
            .flat();
        }

        return {
          mainCategory: category,
          subCategories: upgradeList,
        };
      });

      let amendmentTable = await createDecorTablePdf(filteredDeal, unit, upgradeSet, categoryData, type, project._id, false);

      if (type === 'preview' || type === 'previewPrice') return;

      let newObject = chosenUpgrades(selectedUpgrades).upgradeSet.map((upgrade: IUpgrade) => {
        return {
          ...upgrade,
          project: project._id,
          deal: filteredDeal._id,
          unit: unit._id,
          upgradeTemplate: upgrade.upgradeTemplate ? upgrade.upgradeTemplate?._id : null,
        };
      });

      let decorObject = {
        date: chosenUpgrade.date,
        salesRep: chosenUpgrade?.salesRep._id,
        upgradeSet: newObject,
      };

      let decor = await saveDecor({
        variables: {
          deal: filteredDeal._id,
          unit: unit._id,
          collection: selectedCollection ? selectedCollection._id : null,
          upgrades: decorObject,
          type: selectedUpgrades,
        },
      });

      let deal = { ...filteredDeal };
      deal.upgradeSetOne = decor.data.saveDecor.upgradeSetOne;
      deal.upgradeSetTwo = decor.data.saveDecor.upgradeSetTwo;
      deal.upgradeSetThree = decor.data.saveDecor.upgradeSetThree;

      let pdf = await createPdf(
        selectedMerge,
        project,
        unit,
        filteredDeal.purchasers,
        filteredDeal.deposit,
        filteredDeal.options,
        filteredDeal.realtor[0],
        null,
        null,
        null,
        deal,
        [],
        false,
        [],
        upgradeSet
      );

      let sign = selectedMerge?.signFields.map((sign: any) => {
        return {
          index: sign.index,
          key: sign.key,
          pageNumber: sign.pageNumber,
          x: sign.x,
          y: sign.y,
        };
      });

      const args = {
        status: 'Prepared',
        name: `${selectedMerge?.name} - ${camelToTitle(selectedUpgrades)}`,
        project: project._id,
        deal: filteredDeal._id,
        signFields: sign,
        // signers,
        isAPS: false,
        type: selectedMerge?.type,
        mergeTemplate: selectedMerge?._id,
      };

      let combined = await combinePdfs([pdf, amendmentTable]);

      const blob = new Blob([combined]);
      const file = new File([blob], `decorAmendment.pdf`, { type: 'application/pdf' });

      await createDocument({ variables: { record: args, file: file } });
    }
  };

  const selectAmendmentUpgrade = (upgrade: IUpgrade) => {
    let selectedUpgrade = amendmentUpgrades.find(
      (amendmentUpgrade: IUpgrade) =>
        amendmentUpgrade.name === upgrade.name &&
        amendmentUpgrade.mainCategory === upgrade.mainCategory &&
        amendmentUpgrade.subCategory === upgrade.subCategory
    );

    if (selectedUpgrade) {
      setAmendmentUpgrades(
        amendmentUpgrades.filter(
          (amendmentUpgrade: IUpgrade) =>
            amendmentUpgrade.name !== upgrade.name ||
            amendmentUpgrade.mainCategory !== upgrade.mainCategory ||
            amendmentUpgrade.subCategory !== upgrade.subCategory
        )
      );
    } else {
      setAmendmentUpgrades([...amendmentUpgrades, upgrade]);
    }
  };

  const handleAmendmentAmount = (e: any, upgrade: IUpgrade) => {
    let amendmentUpgradesArray = amendmentUpgrades.map((amendmentUpgrade: IUpgrade) => {
      if (
        amendmentUpgrade.name === upgrade.name &&
        amendmentUpgrade.mainCategory === upgrade.mainCategory &&
        amendmentUpgrade.subCategory === upgrade.subCategory
      ) {
        return {
          ...amendmentUpgrade,
          price: e.target.value * upgrade.upgradeTemplate?.price!,
          quantity: e.target.value,
        };
      } else return amendmentUpgrade;
    });

    setAmendmentUpgrades(amendmentUpgradesArray);
  };

  const getAmendmentQuantity = (upgrade: IUpgrade) => {
    let selectedUpgrade = amendmentUpgrades.find(
      (amendmentUpgrade: IUpgrade) =>
        amendmentUpgrade.name === upgrade.name &&
        amendmentUpgrade.mainCategory === upgrade.mainCategory &&
        amendmentUpgrade.subCategory === upgrade.subCategory
    );
    if (selectedUpgrade) {
      return selectedUpgrade.quantity;
    } else return '1';
  };

  const handleComments = (comments: []) => {
    setComments(comments);
  };

  const handleCreateUpgrade = (name: string, price: string, quantity: string, mainCategory: string, subCategory: string) => {
    let upgrades = chosenUpgrades(selectedUpgrades)!;

    let addedUpgrade = {
      deal: filteredDeal,
      unit: unit,
      project: project,
      quantity: parseInt(quantity, 10),
      upgradeTemplate: null,
      price: parseInt(price, 10),
      name: name,
      mainCategory: mainCategory,
      subCategory: subCategory,
    };

    if (selectedUpgrades === 'upgradeSetOne') {
      setUpgradeSetOne({ ...upgrades, upgradeSet: [...upgrades.upgradeSet, addedUpgrade] });
    } else if (selectedUpgrades === 'upgradeSetTwo') {
      setUpgradeSetTwo({ ...upgrades, upgradeSet: [...upgrades.upgradeSet, addedUpgrade] });
    } else {
      setUpgradeSetThree({ ...upgrades, upgradeSet: [...upgrades.upgradeSet, addedUpgrade] });
    }
    storeDispatch(handleModal(false));
  };

  const modalView = () => {
    switch (modalType) {
      case 'amendment':
        return (
          <Box>
            <Typography variant={'h5'}>Amendment Creation</Typography>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              {chosenUpgrades(selectedUpgrades).upgradeSet.map((upgrade: IUpgrade, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <Grid item sm={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={amendmentUpgrades.some(
                              (amendmentUpgrade: IUpgrade) =>
                                amendmentUpgrade.name === upgrade.name &&
                                amendmentUpgrade.mainCategory === upgrade.mainCategory &&
                                amendmentUpgrade.subCategory === upgrade.subCategory
                            )}
                            onClick={() => selectAmendmentUpgrade(upgrade)}
                          />
                        }
                        label={`${upgrade.name} - ${upgrade.mainCategory} - ${upgrade.subCategory}`}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        fullWidth
                        label={'Amount'}
                        title={'Amount'}
                        name={'amount'}
                        type={'number'}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAmendmentAmount(e, upgrade)}
                        value={getAmendmentQuantity(upgrade)}
                      />
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
            <p>The amendment created will be listed in the APS/Amendments tab</p>
            <p>Please note that the default of what is listed above will be saved.</p>
            <FormControlLabel
              style={{
                marginTop: '10px',
                marginBottom: '15px',
                display: 'flex',
                alignItems: 'center',
              }}
              control={<Checkbox checked={showSelection} onChange={(e) => setShowSelection(!showSelection)} />}
              label={'Show only selected selections'}
            />
            <Box sx={{ mt: 2 }}>
              <Dropdown
                title={'Merge Template'}
                menuList={mergeTemplates.map((mergeTemplate: IMerge) => mergeTemplate.name)}
                name={'mergeTemplate'}
                handleSelect={(e: any) => handleMergeDropdown(e)}
                value={selectedMerge?.name}
              />
              <FlexBetween sx={{ mt: 2 }}>
                <Button onClick={() => storeDispatch(handleModal(false))} variant="contained" color="info">
                  Cancel
                </Button>
                <Button onClick={() => createDecorDocument('amendment')} variant="contained" color="success">
                  Create
                </Button>
              </FlexBetween>
            </Box>
          </Box>
        );
      case 'notes':
        return (
          <CommentsTable
            title={`Notes`}
            deal={filteredDeal}
            unit={filteredDeal.unit!}
            setComments={handleComments}
            commentType={'deal'}
            comments={comments}
            commentCategory={'decor'}
          />
        );
      case 'custom':
        return <CustomUpgrade handleCreateUpgrade={handleCreateUpgrade} />;
    }
  };

  const checkDecorSession = () => {
    if (
      upgradeSetTwo.upgradeSet.length &&
      upgradeTemplates.some((upgrade: IUpgradeTemplate) => upgrade.sessions.includes('Upgrade Set Three'))
    ) {
      return true;
    } else if (
      !upgradeSetTwo.upgradeSet.length &&
      allUpgradeTemplates.some((upgrade: IUpgradeTemplate) => upgrade.sessions.includes('Upgrade Set Two'))
    ) {
      return true;
    } else return false;
  };

  return loading ? (
    <LoadingWrapper title="Loading..." modal={false} />
  ) : (
    <Box sx={{ position: 'relative', height: '100%' }}>
      {upgradeTemplates.length ? (
        view === 'collection' && collections.length ? (
          <Box>
            <FlexBetween>
              <Box sx={{ fontFamily: 'Abadi-Bold', fontSize: '1.5rem' }}>
                <Typography variant="h2" sx={{ mb: 2 }}>
                  <strong>Collections</strong>
                </Typography>
              </Box>
              <Flex sx={{ cursor: 'pointer' }} onClick={() => setView('upgrade')}>
                <ArrowForwardIcon sx={{ alignSelf: 'center' }} />
              </Flex>
            </FlexBetween>
            <Grid container spacing={2}>
              {collections
                .filter((collection) => collection.session === camelToTitle(selectedUpgrades))
                .map((collection: ICollection, index: number) => {
                  return (
                    <Grid key={index} item xl={4} md={6} xs={12}>
                      <CollectionCard
                        index={index}
                        handleCollection={handleCollection}
                        upgradeTemplates={upgradeTemplates}
                        collection={collection}
                        selectedCollection={selectedCollection}
                        portal={project.portal!}
                        unit={unit}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
        ) : (
          <Fade in={true} timeout={2000}>
            <Box sx={{ position: 'relative', height: '100vh' }}>
              <GlobalModal>{modalView()}</GlobalModal>
              {client ? (
                <Typography variant={'h5'}>
                  <strong>
                    Suite {unit.suite}{' '}
                    {chosenUpgrades(selectedUpgrades).collectionTemplate
                      ? ` - ${chosenUpgrades(selectedUpgrades)?.collectionTemplate?.name}`
                      : ''}
                  </strong>
                </Typography>
              ) : null}
              <FlexBetween>
                <Flex>
                  {collections.find((collection: ICollection) => collection.session === camelToTitle(selectedUpgrades)) ? (
                    <ArrowBackIcon onClick={() => setView('collection')} sx={{ alignSelf: 'center', mr: 1, cursor: 'pointer' }} />
                  ) : null}
                  <h2>Upgrades</h2>
                </Flex>
                <Box>
                  {checkDecorSession() ? (
                    <Button
                      onClick={() => handleNewUpgradeSet()}
                      sx={{ height: '50%', alignSelf: 'center', mr: 1 }}
                      variant="contained"
                      color="primary"
                    >
                      Create New Decor Session
                    </Button>
                  ) : null}
                  <Button
                    onClick={() => toggleDrawer(true)}
                    sx={{ height: '50%', alignSelf: 'center' }}
                    variant="contained"
                    color="primary"
                  >
                    View Selections
                  </Button>
                </Box>
                <Drawer anchor={'right'} open={openDrawer} onClose={() => toggleDrawer(false)}>
                  <ColourSelectionDrawer
                    client={client}
                    upgradeType={selectedUpgrades}
                    selectedUpgrades={chosenUpgrades(selectedUpgrades)}
                    selectedCollection={selectedCollection}
                    handlePrice={handlePrice}
                    handleSave={handleSave}
                    users={users}
                    handleSalesDropdown={handleSalesDropdown}
                    openModal={openModal}
                    createDecorDocument={createDecorDocument}
                    addUpgrade={addUpgrade}
                    deleteUpgrade={deleteUpgrade}
                  />
                </Drawer>
              </FlexBetween>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="id-select-label">Sessions</InputLabel>
                    <Select
                      value={selectedUpgrades}
                      labelId="id-label"
                      id="id-select"
                      label="Sessions"
                      name="idType"
                      onChange={handleUpgradeSet}
                    >
                      <MenuItem value={'upgradeSetOne'} aria-label="centered">
                        UPG Session 1
                      </MenuItem>
                      {selectedUpgrades === 'upgradeSetTwo' || upgradeSetTwo.upgradeSet.length ? (
                        <MenuItem value={'upgradeSetTwo'} aria-label="centered">
                          UPG Session 2
                        </MenuItem>
                      ) : null}
                      {selectedUpgrades === 'upgradeSetThree' || upgradeSetThree.upgradeSet.length ? (
                        <MenuItem value={'upgradeSetThree'} aria-label="centered">
                          UPG Session 3
                        </MenuItem>
                      ) : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="id-select-label">Category</InputLabel>
                    <Select
                      value={selectedMainCategory}
                      labelId="id-label"
                      id="id-select"
                      label="Category"
                      name="idType"
                      onChange={handleMainCategory}
                    >
                      {mainCategories.map((mainCategories: string, index: number) => {
                        return (
                          <MenuItem key={index} value={mainCategories}>
                            {mainCategories}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {subCategories.map((subCategory: ISubCategory, index: number) => {
                return (
                  <Box key={index} sx={{ mt: 3 }}>
                    <Typography sx={{ fontSize: '24px' }}>
                      <strong>{subCategory.subCategory}</strong>
                    </Typography>
                    <Grid sx={{ mt: 0.2 }} container spacing={2}>
                      {subCategory.upgrades
                        .filter((upgrade: IUpgradeTemplate) => !upgrade.hide)
                        .map((upgrade: IUpgradeTemplate, index: number) => {
                          return (
                            <Grid key={index} item lg={3} md={4} sm={6} xs={12}>
                              <UpgradeCard
                                upgrade={upgrade}
                                selectedCollection={selectedCollection}
                                selectedUpgrades={chosenUpgrades(selectedUpgrades).upgradeSet}
                                unit={unit}
                                addUpgrade={addUpgrade}
                                deleteUpgrade={deleteUpgrade}
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Box>
                );
              })}
            </Box>
          </Fade>
        )
      ) : (
        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <em>Colour Selection is currently not available</em>
        </Box>
      )}
      {filteredDeal && (unit.status === 'F' || unit.status === 'C') && upgradeTemplates.length ? (
        <Box sx={{ position: 'sticky', bottom: '10px', mr: 1, textAlign: 'right' }}>
          <IconButton
            sx={{ border: '1px solid #000', backgroundColor: '#000', '&.MuiIconButton-root:hover': { backgroundColor: '#000' } }}
            edge="end"
            aria-label="add-note"
            onClick={() => {
              getComments();
              setModalType('notes');
              storeDispatch(handleModal(true));
            }}
          >
            <NotesIcon sx={{ color: '#fff' }} />
          </IconButton>
        </Box>
      ) : null}
    </Box>
  );
};

interface ChildProps {
  client: boolean;
  unit: any;
  filteredDeal: any;
  project: IProject;
  updateDealDocuments: any;
  documents: any;
  setDocuments: any;
}

interface ISubCategory {
  subCategory: string;
  upgrades: IUpgradeTemplate[];
}

const COLLECTIONS = gql`
  query getCollections($project: MongoID!, $unit: MongoID!) {
    getCollections(project: $project, unit: $unit) {
      collections {
        _id
        name
        upgradeTemplates {
          _id
          name
          price
          mainCategory
          description
          subCategory
          lock
          hide
          schemes
          unitsAllowed
          modelsAllowed
          suitesAllowed {
            _id
            suite
          }
          sessions
          upgradeType
          freeUpgrades
        }
        schemes
        unitsAllowed
        modelsAllowed
        suitesAllowed {
          _id
          suite
        }
        primaryColor
        secondaryColor
        session
      }
      upgrades {
        _id
        name
        price
        mainCategory
        subCategory
        description
        lock
        hide
        schemes
        unitsAllowed
        modelsAllowed
        suitesAllowed {
          _id
          suite
        }
        sessions
        freeUpgrades
        upgradeType
        getUrl
      }
    }
  }
`;

const SAVEDECOR = gql`
  mutation saveDecor($deal: MongoID, $unit: MongoID!, $upgrades: NewDecorInput, $type: String!) {
    saveDecor(deal: $deal, unit: $unit, upgrades: $upgrades, type: $type) {
      upgradeSetOne {
        date
        salesRep {
          _id
          fullName
        }
        collectionTemplate {
          _id
          name
          upgradeTemplates {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            schemes
            unitsAllowed
            modelsAllowed
            suitesAllowed {
              _id
              suite
            }
            sessions
            freeUpgrades
            upgradeType
          }
          primaryColor
          secondaryColor
          schemes
          unitsAllowed
          modelsAllowed
          suitesAllowed {
            _id
            suite
          }
        }
        upgradeSet {
          _id
          name
          price
          quantity
          mainCategory
          subCategory
          upgradeTemplate {
            _id
            name
            price
            mainCategory
            description
            subCategory
            lock
            hide
            schemes
            unitsAllowed
            modelsAllowed
            suitesAllowed {
              _id
              suite
            }
            sessions
            freeUpgrades
            upgradeType
          }
        }
      }
      upgradeSetTwo {
        date
        salesRep {
          _id
          fullName
        }
        collectionTemplate {
          _id
          name
          upgradeTemplates {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            schemes
            unitsAllowed
            modelsAllowed
            suitesAllowed {
              _id
              suite
            }
            sessions
            freeUpgrades
            upgradeType
          }
          primaryColor
          secondaryColor
          schemes
          unitsAllowed
          modelsAllowed
          suitesAllowed {
            _id
            suite
          }
        }
        upgradeSet {
          _id
          name
          price
          mainCategory
          subCategory
          quantity
          upgradeTemplate {
            _id
            name
            price
            description
            lock
            hide
            mainCategory
            subCategory
            schemes
            unitsAllowed
            modelsAllowed
            suitesAllowed {
              _id
              suite
            }
            sessions
            freeUpgrades
            upgradeType
          }
        }
      }
      upgradeSetThree {
        date
        salesRep {
          _id
          fullName
        }
        collectionTemplate {
          _id
          name
          upgradeTemplates {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            schemes
            unitsAllowed
            modelsAllowed
            suitesAllowed {
              _id
              suite
            }
            sessions
            freeUpgrades
            upgradeType
          }
          primaryColor
          secondaryColor
          schemes
          unitsAllowed
          modelsAllowed
          suitesAllowed {
            _id
            suite
          }
        }
        upgradeSet {
          _id
          name
          price
          mainCategory
          subCategory
          quantity
          upgradeTemplate {
            _id
            name
            price
            mainCategory
            description
            lock
            hide
            subCategory
            schemes
            unitsAllowed
            modelsAllowed
            suitesAllowed {
              _id
              suite
            }
            sessions
            freeUpgrades
            upgradeType
          }
        }
      }
    }
  }
`;

const GETSALES = gql`
  query userMany($filter: FilterFindManyUserInput, $sort: SortFindManyUserInput) {
    userMany(filter: $filter, sort: $sort) {
      _id
      fullName
    }
  }
`;

const MERGETEMPLATES = gql`
  query mergeTemplateMany($filter: FilterFindManyMergeTemplateInput) {
    mergeTemplateMany(filter: $filter) {
      _id
      project {
        _id
      }
      name
      mergeFields {
        key
        index
        pageNumber
        x
        y
        fontSize
        format
        wrap
      }
      signFields {
        key
        index
        pageNumber
        x
        y
      }
      type
      getUrl
      putUrl
    }
  }
`;

const CREATEDOCUMENT = gql`
  mutation documentCreateOne($record: CreateOneDocumentInput, $file: Upload!) {
    documentCreateOne(record: $record, file: $file) {
      _id
      name
      project {
        _id
      }
      type
      getUrl
      status
      isAPS
      dsEnvelopeId
    }
  }
`;

const GETCOMMENTS = gql`
  query commentMany($filter: FilterFindManyCommentInput) {
    commentMany(filter: $filter, limit: 10000) {
      _id
      user {
        fullName
      }
      unit {
        status
        suite
      }
      deal {
        _id
        realtor {
          fullName
        }
        rescission {
          dateRescinded
        }
        cancelled {
          dateCancelled
        }
      }
      pinned
      comment
      type
      updatedAt
      createdAt
    }
  }
`;

export default ColourSelection;
