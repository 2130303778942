import React, { useContext, useState, useEffect, useMemo } from 'react';
import { addDays, addMonths } from 'date-fns';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { useDropzone } from 'react-dropzone';
import { Box, ToggleButton, ToggleButtonGroup, FormControl, Select, InputLabel, MenuItem, Button } from '@mui/material';
import { addYears } from 'date-fns';

import DealSummary from './dealSummary/DealSummary';
import { UnitContext } from '../../../context/UnitContext';
import { IOption, IDeposits, IDepositStructures } from '../../../types/project';
import { IDeal, IPurchaserInfo, IDealDeposit, IDealOption } from '../../../types/CreateDealForm';
import { IUnit, IDocuments } from '../../../types/unit';
import { convertAllDates, camelToTitle, ordinal_suffix_of } from '../../../utils/Functions';
import Dropdown from '../../common/formControls/Dropdown';
import LoadingWrapper from '../../common/LoadingWrapper';
import PurchaserInfo from './purchaser/PurchaserInfo';
import RealtorInfo from './realtor/RealtorInfo';
import DepositInfo from './DepositInfo';
import OptionInfo from './OptionInfo';
import UnitTransfer from './UnitTransfer';
import UnitInfo from '../UnitInfo';
import { FlexBetween, FlexEnd } from '../../../commonStyles';
import { useSelector } from 'react-redux';
import { selectProject } from '../../../features/project/projectSlice';
import { selectUser } from '../../../features/auth/authSlice';
import { useAppDispatch } from '../../../app/hooks';
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from '../../../utils/Constants';
import { showSuccessSnackbar, showErrorSnackbar } from '../../../features/snackbar/snackbarSlice';
import { useCreateActivity } from '../../../features/activity/activityHooks';
import { accessAllowed } from '../../../features/project/projectHooks';

const DealInfo = (props: ChildProps) => {
  const createActivity = useCreateActivity();
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const { openDialog, handleIndexChange } = props;
  const {
    unit,
    deals,
    setDeals,
    filteredDeal,
    setFilteredDeal,
    documents,
    setDocuments,
    pendingDeposits,
    setPendingDeposits,
    updatePendingDeposit,
    updateDealDocuments,
    document,
    setDocument,
    getRealtors,
    pendingBasePrice,
    rental,
  } = useContext(UnitContext);
  const [unitTransfer, setUnitTransfer] = useState<IUnit | null>(null);
  const [pendingOptions, setPendingOptions] = useState<IDealOption[]>([]);
  const [selectedDeposit, setSelectedDeposit] = useState<string>('');
  const [currentDeal, setCurrentDeal] = useState<IDeal>(filteredDeal);
  const [deposits, setDeposits] = useState<IDealDeposit[]>(filteredDeal && filteredDeal.deposit ? filteredDeal.deposit : []);
  const [errors, setErrors] = useState<any>(null);
  const [fileImage, setFileImage] = useState<any>([]);
  const [depositImages, setDepositImages] = useState<any>(filteredDeal && filteredDeal.depositImages ? filteredDeal.depositImages : []);
  const [unitTransferTotal, setUnitTransferTotal] = useState<number>(unitTransfer?.basePrice);
  const [optionsOnly, setOptionsOnly] = useState<boolean>(false);
  const [keepDeposits, setKeepDeposits] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>('Summary');

  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/jpeg, image/png',
  });

  useEffect(() => {
    if (filteredDeal) {
      setDeposits(filteredDeal.deposit);
      setDepositImages(filteredDeal.depositImages);
    }
  }, [filteredDeal]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const depositList = () => {
    let depositNames = project.depositStructures.map((deposit: IDepositStructures, index: number) => {
      return deposit.name;
    });
    return depositNames;
  };

  const handleDepositSubmit = async (e: any) => {
    e.preventDefault();
    if (errors) {
      storeDispatch(showErrorSnackbar('Invalid Date'));
      return;
    }

    let newAdditionalDeposits: IDealDeposit[] = [];
    let newPendingDeposits: IDealDeposit[] = [];

    if (filteredDeal.pendingDeposits.length > 0) {
      newPendingDeposits = filteredDeal.pendingDeposits.map((deposit: IDealDeposit) => {
        return {
          accountNumber: deposit.accountNumber,
          amount: deposit.amount,
          chequeAmount: deposit.chequeAmount,
          chequeDate: deposit.chequeDate,
          chequeNumber: deposit.chequeNumber,
          chequeType: deposit.chequeType,
          dueDate: deposit.dueDate,
          name: deposit.name,
          _id: deposit._id,
        };
      });
    }

    if (filteredDeal.additionalDeposits.length > 0) {
      newAdditionalDeposits = filteredDeal.additionalDeposits.map((deposit: IDealDeposit) => {
        return {
          accountNumber: deposit.accountNumber,
          amount: deposit.amount,
          chequeAmount: deposit.chequeAmount,
          chequeDate: deposit.chequeDate,
          chequeNumber: deposit.chequeNumber,
          chequeType: deposit.chequeType,
          dueDate: deposit.dueDate,
          name: deposit.name,
          _id: deposit._id,
        };
      });
    }

    let newDeposits = filteredDeal.deposit.map((deposit: IDealDeposit) => {
      if (deposit.chequeType === 'cleared') {
        return {
          accountNumber: deposit.accountNumber,
          amount: deposit.amount,
          chequeAmount: deposit.chequeAmount,
          chequeDate: deposit.chequeDate,
          chequeNumber: deposit.chequeNumber,
          chequeType: deposit.chequeType,
          dueDate: deposit.dueDate,
          clearedDate: new Date(),
          name: deposit.name,
          _id: deposit._id,
        };
      } else {
        return {
          accountNumber: deposit.accountNumber,
          amount: deposit.amount,
          chequeAmount: deposit.chequeAmount,
          chequeDate: deposit.chequeDate,
          chequeNumber: deposit.chequeNumber,
          chequeType: deposit.chequeType,
          dueDate: deposit.dueDate,
          name: deposit.name,
          _id: deposit._id,
        };
      }
    });

    updateDealDeposit({
      variables: {
        depositArray: newDeposits,
        additionalDepositArray: newAdditionalDeposits,
        pendingDepositArray: newPendingDeposits,
        unitId: unit._id,
        dealId: filteredDeal._id,
      },
    });
  };

  const [getPastDeals] = useLazyQuery(GETPASTDEALS, {
    fetchPolicy: 'cache-first',
    variables: {
      filter: {
        unit: unit._id,
        project: project._id,
        cancelledDeals: true,
      },
    },
    onCompleted: (data) => {
      setDeals(data.dealMany);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [createAmendment, { loading: createLoading }] = useMutation(CREATEDOCUMENT, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar(`${data.documentCreateOne.name} Amendment has been created!`));
      createActivity({
        project: project._id,
        user: user._id,
        deal: filteredDeal._id,
        title: `Amendment`,
        content: `${data.documentCreateOne.name} Amendment has been created`,
      });
      let documentIds = documents.map((document: IDocuments) => {
        return document._id;
      });

      // Create Option Amendment without changing deposits
      if (!pendingDeposits.length && filter === 'Options') {
        let removeEmptyOptions = pendingOptions.filter((options: any) => options.purchaseAmount);
        updateDealDocuments({
          variables: {
            _id: filteredDeal._id,
            record: {
              pendingDeposits: [],
              pendingOptions: removeEmptyOptions,
              pendingUnit: null,
              depositName: selectedDeposit,
              documents: [...documentIds, data.documentCreateOne._id],
            },
          },
        });
      } else if (
        pendingDeposits.length &&
        filter === 'Unit Transfer' &&
        pendingDeposits[0]._id &&
        pendingDeposits[0]._id.toString() === filteredDeal.deposit[0]._id.toString()
      ) {
        // Unit Transfer same deposit structure
        let removeEmptyOptions: any[] = [];
        if (pendingOptions.length > 0) {
          removeEmptyOptions = pendingOptions.filter((options: any) => options.purchaseAmount);
        }
        let depositIds = pendingDeposits.map((deposit: IDealDeposit) => deposit._id);
        updateDealDocuments({
          variables: {
            _id: filteredDeal._id,
            record: {
              documents: [...documentIds, data.documentCreateOne._id],
              pendingDeposits: depositIds,
              pendingOptions: removeEmptyOptions.map(({ rental, ...attrs }: any) => attrs),
              depositName: selectedDeposit,
              pendingUnit: unitTransfer ? unitTransfer._id : null,
              pendingRental: parseInt(rental, 10) ? parseInt(rental, 10) : null,
            },
          },
        });
      } else {
        updateDealDocuments({
          variables: { _id: filteredDeal._id, record: { documents: [...documentIds, data.documentCreateOne._id] } },
        });
      }

      setDocuments([...documents, data.documentCreateOne]);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [createDeposits] = useMutation(CREATEDEPOSITS, {
    onCompleted: (data) => {
      let depositIds = data.depositCreateMany.records.map((deposit: IDealDeposit) => {
        return deposit._id;
      });

      let removeEmptyOptions: any[] = [];

      if (pendingOptions.length > 0) {
        removeEmptyOptions = pendingOptions.filter((options: any) => options.purchaseAmount).map(({ rental, ...attrs }: any) => attrs);
      }

      if (optionsOnly) {
        let combined: string[] = [];
        if (filteredDeal.additionalDeposits.length > 0) {
          combined = filteredDeal.additionalDeposits.map((deposit: IDealDeposit) => deposit._id);
          combined = [...combined, depositIds];
          combined = combined.flat();
        } else {
          combined = depositIds;
        }
        updatePendingDeposit({
          variables: {
            _id: filteredDeal._id,
            record: {
              additionalDeposits: combined,
              pendingDeposits: depositIds,
              pendingOptions: removeEmptyOptions,
              pendingUnit: unitTransfer ? unitTransfer._id : null,
              pendingRental: parseInt(rental, 10) ? parseInt(rental, 10) : null,
            },
          },
        });
      } else {
        updatePendingDeposit({
          variables: {
            _id: filteredDeal._id,
            record: {
              pendingDeposits: depositIds,
              pendingOptions: removeEmptyOptions,
              pendingUnit: unitTransfer ? unitTransfer._id : null,
              pendingRental: parseInt(rental, 10) ? parseInt(rental, 10) : null,
            },
          },
        });
      }
      setOptionsOnly(false);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateDealDeposit] = useMutation(UPDATEDEPOSIT, {
    onCompleted: (data) => {
      // Get Old Data to Compare with New Data
      setFilteredDeal({
        ...filteredDeal,
        deposit: data.updateDeposits,
      });
      storeDispatch(showSuccessSnackbar('Deposit(s) have been updated!'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  useEffect(() => {
    handleImageState();
  }, []);

  useEffect(() => {
    getDepositStructure();
  }, [selectedDeposit, unitTransferTotal, optionsOnly, keepDeposits, pendingBasePrice]);

  useEffect(() => {
    getDocument();
  }, [documents]);

  // Document

  const getDocument = () => {
    if (documents) {
      let selectedDocument = documents.find((document: IDocuments) => {
        if (document.status !== 'Completed') {
          return document.type === 'UnitTransfer' || document.type === 'Deposit' || document.type === 'Options';
        }
      });
      if (selectedDocument) {
        setDocument(selectedDocument.type);
      }
    }
  };

  const getDepositStructure = () => {
    if (selectedDeposit) {
      let selectedDepositStructure = project.depositStructures.filter(
        (depositStructure: IDepositStructures) => selectedDeposit === depositStructure.name
      );
      let depositData = selectedDepositStructure[0].deposits.map((data: IDeposits, index: number) => {
        let dealTotalPrice = unitTransferTotal;
        if (optionsOnly) {
          if (filteredDeal.options.length > 0) {
            let filteredPending = pendingOptions.map((option: IDealOption, index: number) => {
              let duplicateOption = filteredDeal.options.find((filteredOption: IDealOption) => filteredOption.name === option.name);
              if (duplicateOption) {
                if (option.purchaseAmount !== duplicateOption.purchaseAmount) {
                  return {
                    name: option.name,
                    purchaseAmount: option?.purchaseAmount! - duplicateOption.purchaseAmount,
                    amount: option?.amount! - duplicateOption.amount,
                  };
                } else
                  return {
                    name: option.name,
                    purchaseAmount: 0,
                    amount: 0,
                  };
              } else return option;
            });
            dealTotalPrice = filteredPending.reduce((a: any, b: any) => {
              return a + b.amount;
            }, 0);
          } else {
            dealTotalPrice = pendingOptions.reduce((a: any, b: any) => {
              return a + b.amount;
            }, 0);
          }
        }

        const balanceFunction = (index: number) => {
          let sum = 0;
          for (let i = 0; i < index; i++) {
            if (selectedDepositStructure[0].deposits[i].type === 'Fixed') {
              sum += selectedDepositStructure[0].deposits[i].amount;
            }
          }
          return sum;
        };

        if (document === 'Deposits') {
          dealTotalPrice = filteredDeal.options.reduce(
            (a: any, b: any) => {
              return a + b.amount;
            },
            pendingBasePrice ? parseInt(pendingBasePrice, 10) : filteredDeal.basePrice
          );
        }
        if (keepDeposits) {
          return {
            ...data,
            amount: filteredDeal.deposit[index].amount,
            dueDate:
              filteredDeal.createdAt && data.dateType !== 'Specific Date'
                ? addDays(new Date(filteredDeal.createdAt), data.daysDue)
                : data.dueDate,
          };
        } else {
          let dueDate =
            filteredDeal.createdAt && data.dateType !== 'Specific Date' && filteredDeal.deposit.length
              ? addDays(new Date(filteredDeal.deposit[0].dueDate), data.daysDue)
              : data.dueDate;

          if (data.dateType === 'Floating Date') {
            dueDate = unit.firmOccupancy
              ? new Date(unit.firmOccupancy)
              : project.firmOccupancy
              ? new Date(project.firmOccupancy)
              : new Date(addYears(new Date(), 5));
          }

          if (data.type === 'Fixed') {
            if (data.daysDue) {
              return {
                ...data,
                dueDate: dueDate,
                percentage: ((data.amount / dealTotalPrice) * 100).toFixed(2),
              };
            } else {
              return {
                ...data,
                dueDate:
                  filteredDeal.createdAt && data.dateType !== 'Specific Date' && filteredDeal.deposit.length
                    ? new Date(filteredDeal.deposit[0].dueDate)
                    : data.dueDate,
                percentage: ((data.amount / dealTotalPrice) * 100).toFixed(2),
              };
            }
          } else if (data.type === 'Balance to 1.5%') {
            return {
              ...data,
              dueDate: dueDate,
              percentage: ((Math.round(dealTotalPrice * 0.015 - balanceFunction(index)) / dealTotalPrice) * 100).toFixed(2),
              amount: Math.round(dealTotalPrice * 0.015 - balanceFunction(index)),
            };
          } else if (data.type === 'Balance to 2.5%') {
            return {
              ...data,
              dueDate: dueDate,
              percentage: ((Math.round(dealTotalPrice * 0.025 - balanceFunction(index)) / dealTotalPrice) * 100).toFixed(2),
              amount: Math.round(dealTotalPrice * 0.025 - balanceFunction(index)),
            };
          } else if (data.type === 'Balance to 3%') {
            return {
              ...data,
              dueDate: dueDate,
              percentage: ((Math.round(dealTotalPrice * 0.03 - balanceFunction(index)) / dealTotalPrice) * 100).toFixed(2),
              amount: Math.round(dealTotalPrice * 0.03 - balanceFunction(index)),
            };
          } else if (data.type === 'Balance to 5%') {
            return {
              ...data,
              dueDate: dueDate,
              percentage: ((Math.round(dealTotalPrice * 0.05 - balanceFunction(index)) / dealTotalPrice) * 100).toFixed(2),
              amount: Math.round(dealTotalPrice * 0.05 - balanceFunction(index)),
            };
          } else if (data.type === 'Balance to 7.5%') {
            return {
              ...data,
              dueDate: dueDate,
              percentage: ((Math.round(dealTotalPrice * 0.075 - balanceFunction(index)) / dealTotalPrice) * 100).toFixed(2),
              amount: Math.round(dealTotalPrice * 0.075 - balanceFunction(index)),
            };
          } else if (data.type === 'Balance to 10%') {
            return {
              ...data,
              dueDate: dueDate,
              percentage: ((Math.round(dealTotalPrice * 0.1 - balanceFunction(index)) / dealTotalPrice) * 100).toFixed(2),
              amount: Math.round(dealTotalPrice * 0.1 - balanceFunction(index)),
            };
          } else if (data.type === 'Balance to 15%') {
            return {
              ...data,
              dueDate: dueDate,
              percentage: ((Math.round(dealTotalPrice * 0.15 - balanceFunction(index)) / dealTotalPrice) * 100).toFixed(2),
              amount: Math.round(dealTotalPrice * 0.15 - balanceFunction(index)),
            };
          } else if (data.type === 'Balance to 10% Monthly') {
            let balance = Math.round(dealTotalPrice * 0.1 - balanceFunction(index));
            let months = Math.ceil((dealTotalPrice * 0.1 - balanceFunction(index)) / data.amount);
            let dueDate = new Date();
            let monthlyArray = [];
            if (data.dateType === 'Every Month after 1st Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[1].dateType !== 'Specific Date'
                  ? addDays(new Date(addDays(new Date(filteredDeal.createdAt), selectedDepositStructure[0].deposits[0].daysDue)), 30)
                  : new Date(selectedDepositStructure[0].deposits[0].dueDate);
            } else if (data.dateType === 'Every Month after 2nd Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[1].dateType !== 'Specific Date'
                  ? addDays(new Date(addDays(new Date(filteredDeal.createdAt), selectedDepositStructure[0].deposits[1].daysDue)), 30)
                  : new Date(selectedDepositStructure[0].deposits[1].dueDate);
            } else if (data.dateType === 'Every Month after 3rd Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[1].dateType !== 'Specific Date'
                  ? addDays(new Date(addDays(new Date(filteredDeal.createdAt), selectedDepositStructure[0].deposits[2].daysDue)), 30)
                  : new Date(selectedDepositStructure[0].deposits[2].dueDate);
            } else if (data.dateType === '1st of Every Month after 1st Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[1].dateType !== 'Specific Date'
                  ? addDays(new Date(addDays(new Date(filteredDeal.createdAt), selectedDepositStructure[0].deposits[0].daysDue)), 30)
                  : new Date(selectedDepositStructure[0].deposits[2].dueDate);

              dueDate = addMonths(dueDate, 1);
              // Add 1 month Prorated
            } else if (data.dateType === '1st of Every Month after 2nd Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[0].dateType !== 'Specific Date'
                  ? new Date(addDays(new Date(filteredDeal.createdAt), selectedDepositStructure[0].deposits[1].daysDue))
                  : new Date(selectedDepositStructure[0].deposits[2].dueDate);

              dueDate = addMonths(dueDate, 1);
            } else if (data.dateType === '1st of Every Month after 3rd Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[1].dateType !== 'Specific Date'
                  ? addDays(new Date(addDays(new Date(filteredDeal.createdAt), selectedDepositStructure[0].deposits[2].daysDue)), 30)
                  : new Date(selectedDepositStructure[0].deposits[2].dueDate);

              dueDate = addMonths(dueDate, 1);
            }

            for (let i = 0; i < months; i++) {
              let newDueDate;
              if (
                data.dateType === 'Every Month after 1st Deposit' ||
                data.dateType === 'Every Month after 2nd Deposit' ||
                data.dateType === 'Every Month after 3rd Deposit'
              ) {
                newDueDate = addDays(dueDate, i * 30);
              } else {
                newDueDate = new Date(dueDate.setMonth(dueDate.getMonth() + 1, 1));
              }
              monthlyArray.push({
                ...data,
                name: `${ordinal_suffix_of(index + (i + 1))} Deposit`,
                dueDate: newDueDate,
                percentage: ((data.amount / dealTotalPrice) * 100).toFixed(2),
                amount: i === months - 1 ? balance - data.amount * i : data.amount,
              });
            }

            return monthlyArray;
          } else if (data.type === 'Balance to 15% Monthly') {
            let balance = Math.round(dealTotalPrice * 0.15 - balanceFunction(index));
            let months = Math.ceil((dealTotalPrice * 0.15 - balanceFunction(index)) / data.amount);
            let dueDate = new Date();
            let monthlyArray = [];
            if (data.dateType === 'Every Month after 1st Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[1].dateType !== 'Specific Date'
                  ? addDays(new Date(addDays(new Date(filteredDeal.createdAt), selectedDepositStructure[0].deposits[0].daysDue)), 30)
                  : new Date(selectedDepositStructure[0].deposits[0].dueDate);
            } else if (data.dateType === 'Every Month after 2nd Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[1].dateType !== 'Specific Date'
                  ? addDays(new Date(addDays(new Date(filteredDeal.createdAt), selectedDepositStructure[0].deposits[1].daysDue)), 30)
                  : new Date(selectedDepositStructure[0].deposits[1].dueDate);
            } else if (data.dateType === 'Every Month after 3rd Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[1].dateType !== 'Specific Date'
                  ? addDays(new Date(addDays(new Date(filteredDeal.createdAt), selectedDepositStructure[0].deposits[2].daysDue)), 30)
                  : new Date(selectedDepositStructure[0].deposits[2].dueDate);
            } else if (data.dateType === '1st of Every Month after 1st Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[1].dateType !== 'Specific Date'
                  ? addDays(new Date(addDays(new Date(filteredDeal.createdAt), selectedDepositStructure[0].deposits[0].daysDue)), 30)
                  : new Date(selectedDepositStructure[0].deposits[0].dueDate);

              dueDate = addMonths(dueDate, 1);
              // Add 1 month Prorated
            } else if (data.dateType === '1st of Every Month after 2nd Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[0].dateType !== 'Specific Date'
                  ? new Date(addDays(new Date(filteredDeal.createdAt), selectedDepositStructure[0].deposits[1].daysDue))
                  : new Date(selectedDepositStructure[0].deposits[1].dueDate);

              dueDate = addMonths(dueDate, 1);
            } else if (data.dateType === '1st of Every Month after 3rd Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[1].dateType !== 'Specific Date'
                  ? addDays(new Date(addDays(new Date(filteredDeal.createdAt), selectedDepositStructure[0].deposits[2].daysDue)), 30)
                  : new Date(selectedDepositStructure[0].deposits[2].dueDate);

              dueDate = addMonths(dueDate, 1);
            }

            for (let i = 0; i < months; i++) {
              let newDueDate;
              if (
                data.dateType === 'Every Month after 1st Deposit' ||
                data.dateType === 'Every Month after 2nd Deposit' ||
                data.dateType === 'Every Month after 3rd Deposit'
              ) {
                newDueDate = addDays(dueDate, i * 30);
              } else {
                newDueDate = new Date(dueDate.setMonth(dueDate.getMonth() + 1, 1));
              }
              monthlyArray.push({
                ...data,
                name: `${ordinal_suffix_of(index + (i + 1))} Deposit`,
                dueDate: newDueDate,
                percentage: ((data.amount / dealTotalPrice) * 100).toFixed(2),
                amount: i === months - 1 ? balance - data.amount * i : data.amount,
              });
            }

            return monthlyArray;
          } else if (data.type === 'Balance to 15% Monthly (36 Months)') {
            let months = 36;
            let monthlyBalance = Math.ceil((dealTotalPrice * 0.15 - balanceFunction(index)) / 36);
            let dueDate = new Date();
            let monthlyArray = [];
            if (data.dateType === 'Every Month after 1st Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[1].dateType !== 'Specific Date'
                  ? addDays(new Date(addDays(new Date(filteredDeal.createdAt), selectedDepositStructure[0].deposits[0].daysDue)), 30)
                  : new Date(selectedDepositStructure[0].deposits[0].dueDate);
            } else if (data.dateType === 'Every Month after 2nd Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[1].dateType !== 'Specific Date'
                  ? addDays(new Date(addDays(new Date(filteredDeal.createdAt), selectedDepositStructure[0].deposits[1].daysDue)), 30)
                  : new Date(selectedDepositStructure[0].deposits[1].dueDate);
            } else if (data.dateType === 'Every Month after 3rd Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[1].dateType !== 'Specific Date'
                  ? addDays(new Date(addDays(new Date(filteredDeal.createdAt), selectedDepositStructure[0].deposits[2].daysDue)), 30)
                  : new Date(selectedDepositStructure[0].deposits[2].dueDate);
            } else if (data.dateType === '1st of Every Month after 1st Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[1].dateType !== 'Specific Date'
                  ? addDays(new Date(selectedDepositStructure[0].deposits[0].dueDate), 30)
                  : new Date(selectedDepositStructure[0].deposits[0].dueDate);

              dueDate = addMonths(dueDate, 1);
              // Add 1 month Prorated
            } else if (data.dateType === '1st of Every Month after 2nd Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[0].dateType !== 'Specific Date'
                  ? addDays(new Date(selectedDepositStructure[0].deposits[1].dueDate), 30)
                  : new Date(selectedDepositStructure[0].deposits[1].dueDate);

              dueDate = addMonths(dueDate, 1);
            } else if (data.dateType === '1st of Every Month after 3rd Deposit') {
              dueDate =
                filteredDeal.createdAt && selectedDepositStructure[0].deposits[1].dateType !== 'Specific Date'
                  ? addDays(new Date(selectedDepositStructure[0].deposits[2].dueDate), 30)
                  : new Date(selectedDepositStructure[0].deposits[2].dueDate);

              dueDate = addMonths(dueDate, 1);
            }

            for (let i = 0; i < months; i++) {
              let newDueDate;
              if (
                data.dateType === 'Every Month after 1st Deposit' ||
                data.dateType === 'Every Month after 2nd Deposit' ||
                data.dateType === 'Every Month after 3rd Deposit'
              ) {
                newDueDate = addDays(dueDate, i * 30);
              } else {
                newDueDate = new Date(dueDate.setMonth(dueDate.getMonth() + 1, 1));
              }
              monthlyArray.push({
                ...data,
                name: `${ordinal_suffix_of(index + (i + 1))} Deposit`,
                dueDate: newDueDate,
                percentage: ((monthlyBalance / dealTotalPrice) * 100).toFixed(2),
                amount: monthlyBalance,
              });
            }

            return monthlyArray;
          } else {
            return {
              ...data,
              dueDate: dueDate,
              percentage: data.amount,
              amount: Math.round((dealTotalPrice * data.amount) / 100),
            };
          }
        }
      });
      setPendingDeposits(depositData.flat());
    } else return;
  };

  const optionValue = (name: string, type: string, numIndex: number) => {
    let selectedOption: any = pendingOptions.find((option: IDealOption, index: number) => {
      return index === numIndex;
    });

    return selectedOption[type];
  };

  const handleImageState = () => {
    let imageArray: any = [];
    let idArray: any = [];

    let purchasers = filteredDeal?.purchasers;
    if (purchasers) {
      for (let i = 0; i < purchasers.length; i++) {
        imageArray.push({
          url: purchasers[i]?.getUrl,
          file: '',
        });
        idArray.push(purchasers[i].identifications);
      }
      setFileImage(imageArray);
    }
  };

  const handleDrop = (acceptedFiles: any, numIndex: number, type: string) => {
    if (acceptedFiles.length === 0) {
      storeDispatch(showErrorSnackbar('This file type is not allowed'));
      return;
    }
    let imageArray: any = [];
    if (type === 'proof') {
      imageArray = fileImage;
    } else if (type === 'purchaser') {
    } else if (type === 'deposit') {
      imageArray = depositImages;
    }

    const file = acceptedFiles[0];
    const fileReader = new FileReader();
    if (file) {
      fileReader.readAsDataURL(file);
    }
    fileReader.onloadend = async () => {
      let newArray = imageArray.map((image: any, index: number) => {
        if (numIndex === index) {
          return { file: file, url: fileReader.result };
        } else return image;
      });

      if (type === 'proof') {
        setFileImage(newArray);
      } else if (type === 'purchaser') {
        let selectedPurchaser = filteredDeal.purchasers.map((purchaser: IPurchaserInfo, index: number) => {
          if (index === numIndex) {
            return {
              ...purchaser,
              identifications: [...purchaser.identifications, { file: file, url: fileReader.result, name: file.name }],
            };
          } else return purchaser;
        });
        setFilteredDeal({
          ...filteredDeal,
          purchasers: selectedPurchaser,
        });
      } else if (type === 'deposit') {
        setFilteredDeal({
          ...filteredDeal,
          depositImages: [
            ...filteredDeal.depositImages,
            {
              url: fileReader.result,
              file: file,
            },
          ],
        });
      }
    };
  };

  const handleDealDropdown = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    let deal = deals.find((filteredDeal: IDeal) => filteredDeal._id === event.target.value);
    setFilteredDeal(deal);
    setDocuments(deal.documents);
  };

  const handleDialog = (type: string, index: number) => {
    handleIndexChange(index);
    if (type === 'addRealtor' || type === 'rescind' || type === 'cancel') {
      getRealtors();
    }
    openDialog(type);
  };

  const addPendingDeposits = (type: string = '') => {
    if (type) {
      setDocument('Deposits');
    }
    setUnitTransferTotal(filteredDeal.basePrice);
    setPendingDeposits([
      ...pendingDeposits,
      {
        name: 'Initial',
        accountNumber: '',
        amount: 0,
        chequeDate: null,
        chequeType: 'replacement',
        deal: filteredDeal._id,
        dueDate: new Date(),
      },
    ]);
  };

  const handleOptions = (type: string = '') => {
    let allOptions = project.options.map((option: any) => {
      return {
        name: option.name,
        purchaseAmount: 0,
        amount: 0,
        rental: option.rental,
      };
    });
    if (type) {
      setUnitTransferTotal(filteredDeal.basePrice);
      setDocument('Options');
    }
    setPendingOptions(allOptions);
  };

  const handleOptionDropdown = (event: any, numIndex: number, optionName: string) => {
    let filteredOption = project.options.find((option: IOption, index: number) => option.name == optionName);
    let selectedOption = pendingOptions.map((option: IDealOption) => {
      if (optionName === option.name) {
        return {
          ...option,
          purchaseAmount: parseFloat(event.target.value),
          amount: event.target.value * filteredOption?.price!,
        };
      } else return option;
    });

    let startingPrice = filteredDeal.basePrice;
    if (unitTransfer) {
      startingPrice = unitTransfer?.basePrice;
    }
    let totalOptions = selectedOption.reduce((a: any, b: any) => {
      return a + b.amount;
    }, startingPrice);

    if (totalOptions === startingPrice) {
      setUnitTransferTotal(startingPrice);
    } else {
      setUnitTransferTotal(totalOptions);
    }
    setPendingOptions(selectedOption);
  };

  const handleOptionInput = (event: any, optionName: string) => {
    let selectedOption = pendingOptions.map((option: IDealOption) => {
      if (optionName === option.name) {
        return {
          ...option,
          amount: parseFloat(event.target.value),
        };
      } else return option;
    });

    let startingPrice = filteredDeal.basePrice;
    if (unitTransfer) {
      startingPrice = unitTransfer?.basePrice;
    }
    let totalOptions = selectedOption.reduce((a: any, b: any) => {
      return a + b.amount;
    }, startingPrice);

    if (totalOptions === startingPrice) {
      setUnitTransferTotal(startingPrice);
    } else {
      setUnitTransferTotal(totalOptions);
    }

    setPendingOptions(selectedOption);
  };

  const handleFilter = (event: React.MouseEvent<HTMLElement>, newFilter: string) => {
    if (newFilter !== null) {
      setFilter(newFilter);
    }
  };

  const tabList = ['summary', 'purchasers', 'realtors', 'deposits', 'options', 'unitTransfer'];

  const handleDropdownFilter = (e: any) => {
    setFilter(e.target.value);
  };

  return (
    <Box
      sx={{
        '& .MuiAccordion-root': {
          border: '1px solid #000',
          padding: '4px',
        },
        '& .MuiAccordionDetails-root': {
          display: 'block',
        },
      }}
    >
      {createLoading ? (
        <LoadingWrapper title="Document is being created..." modal={false} />
      ) : (
        <div>
          <FlexBetween
            sx={{
              flexWrap: 'wrap',
              mb: 2,
            }}
          >
            {filteredDeal ? (
              <ToggleButtonGroup
                sx={{
                  '& .Mui-selected': {
                    // <-- mixing the two classes
                    backgroundColor: '#002044 !important',
                    color: '#fff !important',
                  },
                  color: '#002044 !important',
                  '@media (max-width: 600px)': {
                    display: 'none',
                  },
                  marginBottom: '10px',
                }}
                value={filter}
                exclusive
                onChange={handleFilter}
              >
                <ToggleButton
                  sx={{
                    color: '#000',
                    border: '2px solid #002044',
                  }}
                  value="Summary"
                >
                  Summary
                </ToggleButton>
                <ToggleButton
                  sx={{
                    color: '#000',
                    border: '2px solid #002044',
                  }}
                  value="Purchasers"
                >
                  Purchasers
                </ToggleButton>
                <ToggleButton
                  sx={{
                    color: '#000',
                    border: '2px solid #002044',
                  }}
                  value="Realtors"
                >
                  Realtors
                </ToggleButton>
                <ToggleButton
                  sx={{
                    color: '#000',
                    border: '2px solid #002044',
                  }}
                  value="Deposits"
                >
                  Deposits
                </ToggleButton>
                <ToggleButton
                  sx={{
                    color: '#000',
                    border: '2px solid #002044',
                  }}
                  value="Options"
                >
                  Options
                </ToggleButton>
                <ToggleButton
                  sx={{
                    color: '#000',
                    border: '2px solid #002044',
                  }}
                  value="Unit Transfer"
                >
                  Unit Transfer
                </ToggleButton>
              </ToggleButtonGroup>
            ) : null}
            <Box
              sx={{
                display: 'none',
                '@media (max-width: 600px)': {
                  display: 'block',
                },
              }}
            >
              <Dropdown
                title={'Select a Tab'}
                menuList={tabList.map((tab: string) => camelToTitle(tab))}
                name={'Options'}
                handleSelect={(e: any) => handleDropdownFilter(e)}
                value={filter}
              />
            </Box>
            <FlexEnd>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Past deals</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Past Deals"
                  onOpen={() => getPastDeals()}
                  onChange={(e: any) => handleDealDropdown(e)}
                  sx={{ width: '150px' }}
                >
                  {deals.map((deal: IDeal, index: number) => {
                    let date = new Date();
                    if (deal && deal.rescission.dateRescinded) {
                      date = deal.rescission.dateRescinded;
                    } else if (deal && deal.cancelled.dateCancelled) {
                      date = deal.cancelled.dateCancelled;
                    }
                    return (
                      <MenuItem value={deal._id} key={index}>
                        {deal.purchasers[0].lastName} - {convertAllDates(date, 'P')}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </FlexEnd>
          </FlexBetween>
          <Box
            sx={{
              display: 'flex',
              '@media (max-width: 600px)': {
                flexDirection: 'column-reverse',
              },
            }}
          >
            <Box
              sx={{
                marginRight: '20px',
                '@media (max-width: 600px)': {
                  marginRight: '0',
                },
              }}
            >
              <UnitInfo />
            </Box>
            <div style={{ flexGrow: 1 }}>
              <>
                {filter === 'Summary' ? (
                  <Box
                    sx={{
                      border: '1px solid #000',
                      padding: '20px',
                      marginBottom: '30px',
                    }}
                  >
                    <FlexBetween>
                      <h2
                        style={{
                          margin: 0,
                        }}
                      >
                        Deal Information
                      </h2>
                    </FlexBetween>
                    <DealSummary />
                  </Box>
                ) : null}
                {filter === 'Purchasers' ? (
                  <Box
                    sx={{
                      border: '1px solid #000',
                      padding: '20px',
                      marginBottom: '30px',
                    }}
                  >
                    <PurchaserInfo
                      handleDrop={handleDrop}
                      style={style}
                      currentDeal={currentDeal}
                      openDialog={openDialog}
                      handleDialog={handleDialog}
                    />
                  </Box>
                ) : null}
                {filter === 'Realtors' ? (
                  <Box
                    sx={{
                      border: '1px solid #000',
                      padding: '20px',
                      marginBottom: '30px',
                    }}
                  >
                    <RealtorInfo />
                  </Box>
                ) : null}
                {filter === 'Deposits' ? (
                  <Box
                    sx={{
                      border: '1px solid #000',
                      padding: '20px',
                      marginBottom: '30px',
                    }}
                  >
                    <h2 style={{ margin: 0 }}>Deposits</h2>
                    <DepositInfo
                      style={style}
                      createDeposits={createDeposits}
                      handleDrop={handleDrop}
                      openDialog={openDialog}
                      depositList={depositList}
                      addPendingDeposits={addPendingDeposits}
                      handleDepositSubmit={handleDepositSubmit}
                      selectedDeposit={selectedDeposit}
                      setSelectedDeposit={setSelectedDeposit}
                    />
                  </Box>
                ) : null}
                {filter === 'Options' ? (
                  <Box
                    sx={{
                      border: '1px solid #000',
                      padding: '20px',
                      marginBottom: '30px',
                    }}
                  >
                    <h2 style={{ margin: 0 }}>Options</h2>
                    <OptionInfo
                      pendingOptions={pendingOptions}
                      setPendingOptions={setPendingOptions}
                      createAmendment={createAmendment}
                      handleOptionDropdown={handleOptionDropdown}
                      optionValue={optionValue}
                      handleOptionInput={handleOptionInput}
                      depositList={depositList}
                      addPendingDeposits={addPendingDeposits}
                      handleOptions={handleOptions}
                      selectedDeposit={selectedDeposit}
                      setSelectedDeposit={setSelectedDeposit}
                      createDeposits={createDeposits}
                      openDialog={openDialog}
                      setOptionsOnly={setOptionsOnly}
                      optionsOnly={optionsOnly}
                      keepDeposits={keepDeposits}
                      setKeepDeposits={setKeepDeposits}
                    />
                  </Box>
                ) : null}
                {filteredDeal &&
                !filteredDeal.rescission.dateRescinded &&
                !filteredDeal.cancelled.dateCancelled &&
                filter === 'Unit Transfer' ? (
                  <Box
                    sx={{
                      border: '1px solid #000',
                      padding: '20px',
                      marginBottom: '30px',
                    }}
                  >
                    <h2 style={{ margin: 0 }}>Unit Transfer</h2>
                    <UnitTransfer
                      handleDepositSubmit={handleDepositSubmit}
                      handleOptions={handleOptions}
                      handleOptionDropdown={handleOptionDropdown}
                      handleOptionInput={handleOptionInput}
                      optionValue={optionValue}
                      depositList={depositList}
                      createAmendment={createAmendment}
                      createDeposits={createDeposits}
                      selectedDeposit={selectedDeposit}
                      setSelectedDeposit={setSelectedDeposit}
                      pendingOptions={pendingOptions}
                      setPendingOptions={setPendingOptions}
                      unitTransferTotal={unitTransferTotal}
                      setUnitTransferTotal={setUnitTransferTotal}
                      unitTransfer={unitTransfer}
                      setUnitTransfer={setUnitTransfer}
                    />
                  </Box>
                ) : null}
              </>
            </div>
          </Box>
        </div>
      )}
    </Box>
  );
};

interface ChildProps {
  openDialog: any;
  checked: boolean;
  handleCheckChange: any;
  handleIndexChange: (index: number) => void;
}

const UPDATEDEPOSIT = gql`
  mutation updateDeposits(
    $depositArray: [updateCurrentDepositInput!]
    $additionalDepositArray: [updateDepositInput!]
    $pendingDepositArray: [updateDepositInput!]
    $unitId: MongoID!
    $dealId: MongoID!
  ) {
    updateDeposits(
      depositArray: $depositArray
      additionalDepositArray: $additionalDepositArray
      pendingDepositArray: $pendingDepositArray
      unitId: $unitId
      dealId: $dealId
    ) {
      _id
      name
      amount
      dueDate
      accountNumber
      chequeNumber
      chequeDate
      chequeType
      chequeAmount
      deal {
        _id
      }
    }
  }
`;

const CREATEDEPOSITS = gql`
  mutation depositCreateMany($records: [CreateManyDepositInput!]!) {
    depositCreateMany(records: $records) {
      records {
        _id
        deal {
          _id
        }
        name
        amount
        dueDate
        accountNumber
        chequeDate
        chequeType
        chequeAmount
      }
    }
  }
`;

const CREATEDOCUMENT = gql`
  mutation documentCreateOne($record: CreateOneDocumentInput, $file: Upload!) {
    documentCreateOne(record: $record, file: $file) {
      _id
      name
      project {
        _id
      }
      status
      isAPS
      type
      dsEnvelopeId
    }
  }
`;

const GETPASTDEALS = gql`
  query dealMany($filter: FilterFindManyDealInput) {
    dealMany(filter: $filter) {
      _id
      project {
        _id
        decorModels {
          modelType
          allowed
        }
        tags
      }
      unit {
        _id
        status
        rental
        modelType
        unitType
      }
      purchasers {
        _id
        email
        firstName
        lastName
        identifications {
          _id
          name
          getUrl
        }
        corp
        sin
        dob
        streetAddress
        city
        province
        country
        postalCode
        occupation
        employer
        directors
        businessNumber
        signingOfficers {
          fullName
          dob
          sin
          primaryPhone
          streetAddress
          email
        }
        purchaserType
        primaryPhone
        idType
        idNumber
        idExpiry
        proofExpiry
        proofNumber
        proofType
        unit
        idJurisdiction
        getUrl
        putUrl
      }
      realtor {
        _id
        email
        firstName
        lastName
        fullName
        brokerage
        streetAddress
        city
        province
        country
        postalCode
        brokeragePhone
        brokerageFax
        directPhone
        coopRates {
          type
          days
          date
          description
          percentage
          fixedAmount
        }
      }
      depositName
      deposit {
        _id
        name
        amount
        dueDate
        accountNumber
        chequeNumber
        clearedDate
        chequeDate
        chequeType
        chequeAmount
        deal {
          _id
        }
      }
      additionalDeposits {
        _id
        name
        amount
        dueDate
        accountNumber
        chequeNumber
        chequeDate
        chequeType
        chequeAmount
        deal {
          _id
        }
      }
      pendingDeposits {
        _id
        name
        amount
        dueDate
        accountNumber
        chequeNumber
        chequeAmount
        chequeDate
        chequeType
        deal {
          _id
        }
      }
      pendingOptions {
        name
        purchaseAmount
        amount
      }
      pendingUnit {
        basePrice
        suite
        _id
      }
      joinWaitlist
      options {
        name
        purchaseAmount
        amount
      }
      documents {
        _id
        name
        project {
          _id
        }
        status
        isAPS
        type
        dsEnvelopeId
        getUrl
        createdAt
        updatedAt
      }
      basePrice
      totalPrice
      tags
      mortgage {
        _id
        revisions
        lender
        amount
        getUrl
        putUrl
      }
      debit {
        name
        institution
        branch
        account
        accountHolders {
          fullName
          email
        }
      }
      solicitor {
        solicitor
        firm
        streetAddress
        city
        province
        postalCode
        email
        primaryPhone
      }
      miscellaneous {
        _id
        name
        getUrl
      }
      rescission {
        dateRescinded
        reason
      }
      cancelled {
        dateCancelled
        type
        reason
      }
      salesRep {
        _id
        fullName
      }
      depositImages {
        _id
        name
        getUrl
      }
      thirdParty {
        _id
        fullName
        streetAddress
        dob
        primaryPhone
        email
        occupation
        corpNumber
        relationship
      }
      worksheet {
        _id
        status
      }
      adjustments {
        name
        type
        percentage
        value
        description
        approval
      }
      construction {
        name
        type
        value
        description
        approval
        requiresChange
        tbd
        completed
      }
      upgradeSetOne {
        date
        salesRep {
          _id
          fullName
        }
        collectionTemplate {
          _id
          name
          upgradeTemplates {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            schemes
            unitsAllowed
            modelsAllowed
            sessions
            freeUpgrades
            upgradeType
          }
          unitsAllowed
          modelsAllowed
        }
        upgradeSet {
          _id
          name
          price
          mainCategory
          subCategory
          quantity
          upgradeTemplate {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            unitsAllowed
            modelsAllowed
            freeUpgrades
            upgradeType
            getUrl
          }
        }
      }
      upgradeSetTwo {
        date
        salesRep {
          _id
          fullName
        }
        collectionTemplate {
          _id
          name
          upgradeTemplates {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            schemes
            unitsAllowed
            modelsAllowed
            sessions
            freeUpgrades
            upgradeType
          }
          unitsAllowed
          modelsAllowed
        }
        upgradeSet {
          _id
          name
          price
          mainCategory
          subCategory
          quantity
          upgradeTemplate {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            unitsAllowed
            modelsAllowed
            freeUpgrades
            upgradeType
            getUrl
          }
        }
      }
      upgradeSetThree {
        date
        salesRep {
          _id
          fullName
        }
        collectionTemplate {
          _id
          name
          upgradeTemplates {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            schemes
            unitsAllowed
            modelsAllowed
            sessions
            freeUpgrades
            upgradeType
          }
          unitsAllowed
          modelsAllowed
        }
        upgradeSet {
          _id
          name
          price
          mainCategory
          subCategory
          quantity
          upgradeTemplate {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            unitsAllowed
            modelsAllowed
            freeUpgrades
            upgradeType
            getUrl
          }
        }
      }
      draft
      preparedDate
      signDate
      executeDate
      firmDate
      auditor {
        _id
        fullName
      }
      auditDate
      createdAt
    }
  }
`;

export default DealInfo;
