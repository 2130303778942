import { useContext, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Box } from '@mui/material';

import { useAppDispatch } from '../../../app/hooks';
import { showSuccessSnackbar, showErrorSnackbar } from '../../../features/snackbar/snackbarSlice';
import { UnitContext } from '../../../context/UnitContext';
import Solicitor from './Solicitor';
import ThirdParty from './ThirdParty';
import Mortgage from './Mortgage';
import { Container } from '../../../commonStyles';
import Miscellaneous from './Miscellaneous';
import Debit from './Debit';

const SupplementalDocuments = () => {
  const storeDispatch = useAppDispatch();
  const { filteredDeal, setFilteredDeal } = useContext(UnitContext);
  const [miscellaneous, setMiscellaneous] = useState<any>(filteredDeal && filteredDeal.miscellaneous ? filteredDeal.miscellaneous : []);
  const [mpaImage, setMpaImage] = useState<any>({
    file: '',
    getUrl: '',
  });
  const [errors, setErrors] = useState<any>({});

  const [updateDealThirdParty] = useMutation(UPDATE, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Third Party Information Updated!'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateDebit] = useMutation(UPDATE, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('PAD Information Updated!'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateSolicitor] = useMutation(UPDATE, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Solicitor Info has been saved!'));
      setFilteredDeal({
        ...filteredDeal,
        solicitor: data.dealUpdateById.record.solicitor,
      });
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [createMiscellaneous] = useMutation(MISCELLANEOUS, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Document Uploaded!'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [deleteSupport] = useMutation(DELETESUPPORT, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Document has been deleted'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const handleDrop = (acceptedFiles: any, type: string) => {
    if (acceptedFiles.length === 0) {
      storeDispatch(showErrorSnackbar('File type is not allowed'));
      return;
    }
    const file = acceptedFiles[0];
    const fileReader = new FileReader();
    if (file) {
      fileReader.readAsDataURL(file);
    }
    fileReader.onloadend = async () => {
      if (type === 'miscellaneous') {
        setMiscellaneous([
          ...miscellaneous,
          {
            url: fileReader.result,
            file: file,
          },
        ]);
      }
      if (type === 'mpa') {
        setMpaImage({
          getUrl: fileReader.result,
          file: file,
        });
      }
    };
  };

  return (
    <Box
      sx={{
        p: 2,
        '& .MuiAccordion-root': {
          border: '1px solid #000',
          padding: '4px',
        },
        '& .MuiAccordionDetails-root': {
          display: 'block',
        },
        '& .MuiFormHelperText-root': {
          color: 'red',
        },
      }}
    >
      {filteredDeal ? (
        <div>
          <Container sx={{ mb: 2 }}>
            <Mortgage
              errors={errors}
              setErrors={setErrors}
              createMiscellaneous={createMiscellaneous}
              deleteSupport={deleteSupport}
              handleDrop={handleDrop}
              mpaImage={mpaImage}
              setMpaImage={setMpaImage}
            />
          </Container>
          <Container sx={{ mb: 2 }}>
            <Solicitor errors={errors} updateSolicitor={updateSolicitor} />
          </Container>
          <Container sx={{ mb: 2 }}>
            <ThirdParty errors={errors} updateDealThirdParty={updateDealThirdParty} />
          </Container>
          <Container sx={{ mb: 2 }}>
            <Debit errors={errors} updateDebit={updateDebit} />
          </Container>
          <Container
            sx={{
              mb: 2,
            }}
          >
            <Miscellaneous
              miscellaneous={miscellaneous}
              setMiscellaneous={setMiscellaneous}
              createMiscellaneous={createMiscellaneous}
              deleteSupport={deleteSupport}
              handleDrop={handleDrop}
            />
          </Container>
        </div>
      ) : (
        <Box sx={{ textAlign: 'center' }}>
          <em>There are currently no active deals for this unit.</em>
        </Box>
      )}
    </Box>
  );
};

const UPDATE = gql`
  mutation dealUpdateById($_id: MongoID!, $record: UpdateByIdDealInput!) {
    dealUpdateById(_id: $_id, record: $record) {
      record {
        project {
          _id
        }
        mortgage {
          _id
          revisions
          lender
          amount
          getUrl
        }
        solicitor {
          solicitor
          firm
          streetAddress
          city
          province
          postalCode
          email
          primaryPhone
        }
        deposit {
          name
          amount
          dueDate
          accountNumber
          chequeNumber
          clearedDate
          chequeDate
        }
        thirdParty {
          fullName
          streetAddress
          dob
          primaryPhone
          email
          occupation
          corpNumber
          relationship
        }
      }
    }
  }
`;

const MISCELLANEOUS = gql`
  mutation outstandingsUpload($id: MongoID!, $name: String!, $file: Upload!, $type: String!) {
    outstandingsUpload(id: $id, name: $name, file: $file, type: $type) {
      miscellaneous {
        name
        getUrl
      }
      mortgage {
        getUrl
      }
    }
  }
`;

const DELETESUPPORT = gql`
  mutation deleteSupportDocuments($_id: MongoID!, $documentId: MongoID!, $deleteFile: Boolean!, $type: String!) {
    deleteSupportDocuments(_id: $_id, documentId: $documentId, deleteFile: $deleteFile, type: $type) {
      mortgage {
        lender
        amount
      }
      miscellaneous {
        _id
        name
      }
    }
  }
`;

export default SupplementalDocuments;
