import { useState, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { Typography, Box, Button, Grid, Paper, Divider, Tooltip, Modal, Fade, Autocomplete, TextField } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import MergeIcon from '@mui/icons-material/Merge';

import { FlexBetween } from '../../../commonStyles';
import EditPackage from './EditPackage';
import { IPackage } from '../../../types/package';
import { IUnit } from '../../../types/unit';
import { selectProject } from '../../../features/project/projectSlice';
import { camelToTitle, downloadPackage, normalToCamel, urlName, convertAllDates } from '../../../utils/Functions';
import { useAppDispatch } from '../../../app/hooks';
import { showSuccessSnackbar } from '../../../features/snackbar/snackbarSlice';
var QRCode = require('qrcode');

export const modelPackageReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'ADD':
      return [...state, action.payload];
    case 'ADDALL':
      return action.payload;
    case 'UPDATE':
      return state.map((state: any) => {
        if (state._id === action.payload._id) {
          return action.payload;
        } else return state;
      });
    case 'DELETE':
      return state.filter((state: any) => state._id !== action.payload._id);
    default:
      throw new Error();
  }
};

const Packages = ({ editable }: { editable: boolean }) => {
  const project = useSelector(selectProject);
  const storeDispatch = useAppDispatch();
  const [view, setView] = useState<string>('list');
  const [modelPackage, setModelPackage] = useState<IPackage | null>(null);
  const [modelPackageState, modelPackageDispatch] = useReducer(modelPackageReducer, []);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedPackages, setSelectedPackages] = useState<any[]>([]);

  const { loading } = useQuery(MODELPACKAGES, {
    skip: project && !project._id,
    variables: { filter: editable ? { project: project._id } : { project: project._id, active: true } },
    onCompleted: (data) => {
      modelPackageDispatch({ type: 'ADDALL', payload: data.modelPackageMany });
    },
  });

  const [editModelPackage] = useMutation(EDITMODELPACKAGE, {
    onCompleted: (data) => {
      modelPackageDispatch({ type: 'UPDATE', payload: data.modelPackageUpdateById.record });
      storeDispatch(showSuccessSnackbar('Package Edited!'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [addModelPackage] = useMutation(ADDMODELPACKAGE, {
    onCompleted: (data) => {
      modelPackageDispatch({ type: 'ADD', payload: data.modelPackageCreateOne.record });
      storeDispatch(showSuccessSnackbar('Package Added!'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [deleteModelPackage] = useMutation(REMOVEMODELPACKAGE, {
    onCompleted: (data) => {
      modelPackageDispatch({ type: 'DELETE', payload: { _id: data.modelPackageRemoveById.record._id } });
      storeDispatch(showSuccessSnackbar('Package Removed!'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [getMarketingFloorPlans] = useLazyQuery(GETMARKETINGFLOORPLANS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const viewPackage = (modelPackage: IPackage) => {
    let suites = [...modelPackage.units].filter((unit: IUnit) => unit.status === 'HL' || unit.status === 'PL');

    const getPackageHeaders = (header: string) => {
      switch (header) {
        case 'Model':
          return 'modelType';
        case 'Suite Type':
          return 'unitType';
        case 'Floor':
          return 'level';
        case 'View':
          return 'exposure';
        case 'Interior Size':
          return 'size';
        case 'Exterior Size':
          return 'outdoorSize';
        case 'Total Size':
          return 'totalSize';
        case 'Starting Price':
        case 'Starting Price*':
          return 'basePrice';
        case 'Lease Amount':
          return 'rental';
        default:
          return normalToCamel(header);
      }
    };

    let packageHeaders = modelPackage.columns.map((header: string) => {
      return {
        label: header,
        id: getPackageHeaders(header),
      };
    });

    let floorplans = suites.map((unit: IUnit) => {
      return {
        _id: unit._id,
        modelType: unit.modelType,
        suite: unit.suite,
        unitType: unit.unitType,
        basePrice: unit.basePrice,
        rental: unit.rental ? unit.rental : null,
        size: unit.size,
      };
    });

    let qrCode: any = null;

    if (modelPackage.qrCode) {
      let opts = {
        errorCorrectionLevel: 'H',
        type: 'image/jpeg',
        quality: 0.3,
        margin: 1,
        color: {
          dark: '#00142a',
          light: '#fff',
        },
      };

      const generateQR = async (text: string) => {
        try {
          return await QRCode.toDataURL(text, opts);
        } catch (err) {
          console.error(err);
        }
      };

      qrCode = generateQR(
        process.env.REACT_APP_ENV === 'local'
          ? `localhost:3002/${urlName(project.name)}`
          : `http://portal.rdsre.ca/${urlName(project.name)}`
      );
    }

    getMarketingFloorPlans({ variables: { units: floorplans, project: project._id } }).then((res) => {
      if (res.data.getMarketingFloorPlans.length > 0) {
        let floorplans = [...res.data.getMarketingFloorPlans].map((unit: any) =>
          unit.pdfFloorPlan ? unit.pdfFloorPlan : unit.marketingFloorPlan
        );
        downloadPackage(
          [suites],
          [packageHeaders],
          `${project.name} - ${modelPackage.name}`,
          [modelPackage],
          floorplans,
          [qrCode],
          project
        );
      }
    });
  };

  const copyPackage = (modelPackage: IPackage) => {
    addModelPackage({
      variables: {
        record: {
          name: `${modelPackage.name} (COPY)`,
          title: modelPackage.title,
          project: project._id,
          active: modelPackage.active,
          units: modelPackage.units.map((unit: IUnit) => unit._id),
          columns: modelPackage.columns,
          sortOrder: modelPackage.sortOrder,
          qrCode: modelPackage.qrCode,
          descriptions: modelPackage.descriptions,
        },
      },
    });
  };

  const deletePackage = (modelPackage: IPackage) => {
    deleteModelPackage({
      variables: { _id: modelPackage._id },
    });
  };

  const combinePackage = (modelPackage: IPackage) => {
    let packages = [modelPackage, ...selectedPackages];

    let suites: any[] = [];
    let headers: any[] = [];
    let qrCodes: any[] = [];
    for (const modelPackage of packages) {
      suites.push([...modelPackage.units].filter((unit: IUnit) => unit.status === 'HL' || unit.status === 'PL'));

      const getPackageHeaders = (header: string) => {
        switch (header) {
          case 'Model':
            return 'modelType';
          case 'Suite Type':
            return 'unitType';
          case 'Floor':
            return 'level';
          case 'View':
            return 'exposure';
          case 'Interior Size':
            return 'size';
          case 'Exterior Size':
            return 'outdoorSize';
          case 'Total Size':
            return 'totalSize';
          case 'Starting Price':
          case 'Starting Price*':
            return 'basePrice';
          case 'Lease Amount':
            return 'rental';
          default:
            return normalToCamel(header);
        }
      };

      let packageHeaders = modelPackage.columns.map((header: string) => {
        return {
          label: header,
          id: getPackageHeaders(header),
        };
      });

      headers.push(packageHeaders);

      let qrCode: any = null;

      if (modelPackage.qrCode) {
        let opts = {
          errorCorrectionLevel: 'H',
          type: 'image/jpeg',
          quality: 0.3,
          margin: 1,
          color: {
            dark: '#00142a',
            light: '#fff',
          },
        };

        const generateQR = async (text: string) => {
          try {
            return await QRCode.toDataURL(text, opts);
          } catch (err) {
            console.error(err);
          }
        };

        qrCode = generateQR(
          process.env.REACT_APP_ENV === 'local'
            ? `localhost:3002/${urlName(project.name)}`
            : `http://portal.rdsre.ca/${urlName(project.name)}`
        );
      }

      qrCodes.push(qrCode);
    }

    let uniqueFloorplans = [...new Map(suites.flat().map((item) => [item['_id'], item])).values()];

    let floorplans = uniqueFloorplans.map((unit: IUnit) => {
      return {
        _id: unit._id,
        modelType: unit.modelType,
        suite: unit.suite,
        unitType: unit.unitType,
        basePrice: unit.basePrice,
        rental: unit.rental ? unit.rental : null,
        size: unit.size,
      };
    });

    getMarketingFloorPlans({ variables: { units: floorplans, project: project._id } }).then((res) => {
      if (res.data.getMarketingFloorPlans.length > 0) {
        let floorplans = [...res.data.getMarketingFloorPlans].map((unit: any) => unit.marketingFloorPlan);
        downloadPackage(suites, headers, `${project.name} - Combined Package`, packages, floorplans, qrCodes, project);
      }
    });
  };

  return (!editable && !loading && modelPackageState.length) || editable ? (
    <Box sx={{ py: 2, px: editable ? 0 : 2 }}>
      {view === 'list' ? (
        <>
          <Modal
            open={open}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              m: 3,
            }}
            onClose={() => setOpen(false)}
          >
            <Fade in={open}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: {
                    xs: 300,
                    sm: 700,
                    md: 800,
                    lg: 1200,
                  },
                  maxHeight: {
                    xs: 300,
                    sm: 600,
                    md: 600,
                    lg: 650,
                    xl: 850,
                  },
                  overflowY: 'auto',
                  backgroundColor: '#fff',
                  padding: '20px',
                  border: '2px solid #000',
                }}
              >
                <Typography variant="h5">Combine Packages</Typography>
                <Autocomplete
                  multiple
                  sx={{ my: 2 }}
                  options={modelPackageState}
                  getOptionLabel={(option: any) => {
                    return `${option.name}`;
                  }}
                  getOptionDisabled={(option) => option.name === modelPackage?.name}
                  isOptionEqualToValue={(option, value) => option === value}
                  freeSolo={false}
                  value={selectedPackages}
                  onChange={(event: any, newValue: any | null) => {
                    setSelectedPackages(newValue.map((option: string) => option));
                  }}
                  renderInput={(params) => <TextField {...params} label="Packages" size="medium" />}
                />
                <Box>
                  <Button variant="contained" color="success" onClick={() => combinePackage(modelPackage!)}>
                    Combine Package
                  </Button>
                </Box>
              </Box>
            </Fade>
          </Modal>
          <FlexBetween>
            <Typography variant="h5" gutterBottom component="div" sx={{ mb: 0 }}>
              Packages
            </Typography>
            {editable ? (
              <Button
                onClick={() => {
                  setModelPackage(null);
                  setView('edit');
                }}
                variant="contained"
                color="primary"
              >
                Create Package
              </Button>
            ) : null}
          </FlexBetween>
          <Grid spacing={2} container sx={{ mt: 1 }}>
            {modelPackageState.map((modelPackage: any, index: number) => {
              return (
                <Grid key={index} item sm={6} md={4} lg={3}>
                  <Paper
                    elevation={12}
                    sx={{
                      borderRadius: '8px',
                    }}
                  >
                    <Box
                      onClick={() => {
                        if (editable) {
                          setModelPackage(modelPackage);
                          setView('edit');
                        } else {
                          viewPackage(modelPackage);
                        }
                      }}
                      sx={{ p: 2 }}
                    >
                      <Typography sx={{ mb: 2 }} variant={'h3'}>
                        <strong>{modelPackage.name}</strong>
                      </Typography>
                      <Box>
                        Title: <strong>{modelPackage.title}</strong>
                      </Box>
                      {editable ? (
                        <Box>
                          Active:{' '}
                          <strong style={{ color: modelPackage.active ? 'green' : 'red' }}>{modelPackage.active ? 'True' : 'False'}</strong>
                        </Box>
                      ) : null}
                      <Box>
                        Sort Order: <strong>{camelToTitle(modelPackage.sortOrder)}</strong>
                      </Box>
                      <Box>
                        QR Code: <strong>{modelPackage.qrCode ? 'Yes' : 'No'}</strong>
                      </Box>
                      <Box>
                        Last Updated: <strong>{convertAllDates(modelPackage.updatedAt, 'PPpp')}</strong>
                      </Box>
                    </Box>
                    <>
                      <Divider />
                      <FlexBetween sx={{ p: 1, backgroundColor: '#b4c3c3' }}>
                        <Box>
                          <Tooltip title="View Package">
                            <PictureAsPdfIcon sx={{ mr: 1, cursor: 'pointer', color: 'red' }} onClick={() => viewPackage(modelPackage)} />
                          </Tooltip>
                          {modelPackageState.length > 1 ? (
                            <Tooltip title="Combine Packages">
                              <MergeIcon
                                sx={{ mr: 1, cursor: 'pointer', color: 'black' }}
                                onClick={() => {
                                  setModelPackage(modelPackage);
                                  setOpen(!open);
                                }}
                              />
                            </Tooltip>
                          ) : null}
                        </Box>
                        {editable ? (
                          <Box>
                            <Tooltip title="Copy Package">
                              <ContentCopyIcon sx={{ mr: 1, cursor: 'pointer' }} onClick={() => copyPackage(modelPackage)} />
                            </Tooltip>
                            <Tooltip title="Delete Package">
                              <DeleteForeverIcon
                                sx={{ mr: 1, cursor: 'pointer', color: 'orange' }}
                                onClick={() => deletePackage(modelPackage)}
                              />
                            </Tooltip>
                          </Box>
                        ) : null}
                      </FlexBetween>
                    </>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </>
      ) : (
        <EditPackage modelPackage={modelPackage} setView={setView} addModelPackage={addModelPackage} editModelPackage={editModelPackage} />
      )}
    </Box>
  ) : null;
};

const MODELPACKAGES = gql`
  query modelPackageMany($filter: FilterFindManyModelPackageInput) {
    modelPackageMany(filter: $filter, limit: 10000) {
      _id
      name
      active
      project {
        _id
      }
      s3Key
      packageGetUrl
      packagePutUrl
      units {
        _id
        basePrice
        modelType
        bathroom
        size
        outdoorSize
        level
        unitType
        exposure
        status
        suite
        totalSize
        tier
        rental
      }
      columns
      sortOrder
      qrCode
      descriptions {
        type
        content {
          type
          text
          marks
        }
      }
      title
      updatedAt
    }
  }
`;

const ADDMODELPACKAGE = gql`
  mutation modelPackageCreateOne($record: CreateOneModelPackageInput!) {
    modelPackageCreateOne(record: $record) {
      record {
        _id
        name
        active
        project {
          _id
        }
        s3Key
        packageGetUrl
        packagePutUrl
        units {
          _id
          basePrice
          modelType
          bathroom
          size
          outdoorSize
          level
          unitType
          exposure
          status
          suite
          totalSize
          tier
          rental
        }
        columns
        sortOrder
        qrCode
        descriptions {
          type
          content {
            type
            text
            marks
          }
        }
        title
        updatedAt
      }
    }
  }
`;

const EDITMODELPACKAGE = gql`
  mutation modelPackageUpdateById($_id: MongoID!, $record: UpdateByIdModelPackageInput!) {
    modelPackageUpdateById(_id: $_id, record: $record) {
      record {
        _id
        name
        active
        project {
          _id
        }
        s3Key
        packageGetUrl
        packagePutUrl
        units {
          _id
          basePrice
          bathroom
          modelType
          size
          outdoorSize
          level
          unitType
          exposure
          status
          suite
          totalSize
          tier
          rental
        }
        columns
        sortOrder
        qrCode
        descriptions {
          type
          content {
            type
            text
            marks
          }
        }
        title
        updatedAt
      }
    }
  }
`;

const REMOVEMODELPACKAGE = gql`
  mutation modelPackageRemoveById($_id: MongoID!) {
    modelPackageRemoveById(_id: $_id) {
      record {
        _id
      }
    }
  }
`;

const GETMARKETINGFLOORPLANS = gql`
  query getMarketingFloorPlans($units: [NewMarketingInput!], $project: MongoID!) {
    getMarketingFloorPlans(units: $units, project: $project) {
      _id
      suite
      size
      modelType
      rental
      unitType
      marketingFloorPlan
      pdfFloorPlan
      basePrice
    }
  }
`;

export default Packages;
