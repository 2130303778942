import { useState, SetStateAction, Dispatch, useContext } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Checkbox,
  TextField,
  FormControlLabel,
  FormControl,
  FormGroup,
  Autocomplete,
  InputAdornment,
  Select,
  InputLabel,
  MenuItem,
} from '@mui/material';
import CustomDialog from '../../../common/CustomDialog';
import { useAppDispatch } from '../../../../app/hooks';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../../features/snackbar/snackbarSlice';
import { IRealtorInfo } from '../../../../types/CreateDealForm';
import { UnitContext } from '../../../../context/UnitContext';
import { capitalizeFirstLetter, numToCurrency } from '../../../../utils/Functions';
import { selectProject } from '../../../../features/project/projectSlice';
import { unitStatusTitles } from '../../../../utils/Constants';
import { selectUser } from '../../../../features/auth/authSlice';
import { IUnitHistory } from '../../../../types/unit';
import { useCreateActivity } from '../../../../features/activity/activityHooks';
import { IDocuments } from '../../../../types/unit';

const VoidDialog = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const navigate = useNavigate();
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const createActivity = useCreateActivity();
  const { dialogType, setDialogType, dialogOpen, setDialogOpen } = props;
  const { unit, setUnit, filteredDeal, setFilteredDeal, setDocuments, realtors } = useContext(UnitContext);

  const [checked, setChecked] = useState<boolean>(false);
  const [selectedRealtor, setSelectedRealtor] = useState<IRealtorInfo>({});
  const [reason, setReason] = useState<string>('Admin Error');
  const [cancelType, setCancelType] = useState<string>('Admin');
  const [newPrice, setNewPrice] = useState<number>(unit.basePrice);
  const [newStatus, setNewStatus] = useState<string>('M');

  const [updateRegistrantStatus] = useMutation(UPDATEREGISTRANT, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateUnit] = useMutation(UPDATEUNIT, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Deal has been rescinded!'));
      setUnit(data.unitUpdateById.record);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateWorksheet] = useMutation(UPDATEWORKSHEET, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [deleteManyDocuments] = useMutation(DELETEMANYDOCUMENTS, {
    onCompleted: (data) => {
      setDocuments([]);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateDeal] = useMutation(UPDATEDEAL, {
    onCompleted: (data) => {
      let dealStatus = 'Rescinded';
      if (data.dealUpdateById.record.cancelled.dateCancelled) {
        dealStatus = 'Cancelled';
      }
      createActivity({
        project: project._id,
        user: user._id,
        deal: filteredDeal._id,
        title: `Suite - ${unit.suite} ${dealStatus} `,
        content: `${data.dealUpdateById.record.purchasers[0].fullName}`,
      });
      setFilteredDeal(null);
      setDialogOpen(false);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const handleCloseSuccess = async (type: string) => {
    if (!checked || !reason) {
      storeDispatch(showErrorSnackbar(`The checkbox/reason is missing`));
      return;
    }
    await voidContent();
    if (type === 'recreate') {
      await navigate(`/${project._id}/dashboard/create-deal/${unit._id}/deal/${filteredDeal._id}`);
    }
  };

  const dialogContent = () => {
    return (
      <div>
        <div>
          <strong>
            Please note that this deal cannot be recovered once it has been rescinded. Please confirm with management to proceed.
          </strong>
        </div>
        <Box sx={{ mt: 2 }}>
          {dialogType === 'cancel' ? (
            <div>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="cancel">Select a Cancel Type</InputLabel>
                <Select
                  labelId="cancel-label"
                  value={cancelType}
                  label="Merge Template Type"
                  onChange={(e) => setCancelType(e.target.value)}
                  title={'Select a Cancel Type'}
                >
                  {['Admin', 'Purchaser'].map((value: string, index: number) => {
                    return (
                      <MenuItem key={index} value={value}>
                        {value}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          ) : null}
          <TextField
            id="reasons"
            placeholder={`Please enter the reason for ${dialogType}`}
            multiline
            variant={'outlined'}
            rows={4}
            fullWidth
            value={reason}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setReason(e.target.value)}
            required={true}
          />
          <Box sx={{ mt: 2 }}>
            Current Price: <strong>{numToCurrency.format(unit.basePrice)}</strong>
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              name={'newPrice'}
              type={'number'}
              title={'Set New Price'}
              label={'Set New Price'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewPrice(parseFloat(e.target.value))}
              value={newPrice}
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="cancel">Select a Status</InputLabel>
              <Select
                labelId="status-label"
                value={newStatus}
                label="Select a Status"
                fullWidth
                onChange={(e) => setNewStatus(e.target.value)}
                title={'Select a Status'}
              >
                {[...unitStatusTitles, ...project.status]
                  .filter(
                    (status) =>
                      status.code === 'A' ||
                      status.code === 'D' ||
                      status.code === 'M' ||
                      status.code === 'CH' ||
                      status.code === 'HL' ||
                      status.code === 'HL2' ||
                      status.code === 'HL3' ||
                      status.code === 'SHL' ||
                      status.code === 'PL'
                  )
                  .map((value: any, index: number) => {
                    return (
                      <MenuItem key={index} value={value.code}>
                        {value.code}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ mt: 2 }}>
            <FormControl
              sx={{
                width: '100%',
                '& .MuiFormLabel-asterisk': {
                  color: 'red',
                },
              }}
            >
              <Autocomplete
                sx={{
                  width: '100%',
                  '& .MuiFormLabel-asterisk': {
                    color: 'red',
                  },
                }}
                id={'search'}
                disableClearable
                freeSolo={false}
                options={realtors}
                getOptionLabel={(option: any) => `${option.firstName} ${option.lastName} ${option.email}`}
                // getOptionSelected={(option, value) => option === value}
                onChange={(e, values) => setSelectedRealtor(values)}
                renderInput={(params) => <TextField required={false} {...params} size="medium" label={'Set Allocation'} />}
              />
            </FormControl>
          </Box>
          <FormControl component="fieldset">
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    required={true}
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label={`Yes, I would like to ${dialogType} this deal.`}
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>
        </Box>
      </div>
    );
  };

  const voidContent = async () => {
    for (const purchaser of filteredDeal.purchasers) {
      await updateRegistrantStatus({
        variables: {
          email: purchaser.email,
          project: project._id,
          status: 'Rescinded',
          rating: 'A',
          type: dialogType === 'rescind' || cancelType === 'Purchaser' ? 'rescind' : 'cancel',
        },
      });
    }

    let historyDescription = [`Purchaser ${filteredDeal.purchasers[0].firstName} ${filteredDeal.purchasers[0].lastName} has rescinded.`];
    let newAllocation = null;
    if (newPrice) {
      historyDescription.push(
        `The price of the unit has gone from ${numToCurrency.format(unit.basePrice)} -> ${numToCurrency.format(newPrice)}`
      );
    }
    if (newStatus) {
      historyDescription.push(`Status has gone from ${unit.status} -> ${newStatus}`);
    }
    if (selectedRealtor && Object.keys(selectedRealtor).length !== 0) {
      historyDescription.push(
        `Allocation has gone from ${unit.allocation.fullName} -> ${selectedRealtor.firstName} ${selectedRealtor.lastName}`
      );
      newAllocation = selectedRealtor._id;
    }

    let description = historyDescription.join(', ');

    await updateUnit({
      variables: {
        _id: unit._id,
        record: {
          status: newStatus,
          basePrice: newPrice ? newPrice : unit.basePrice,
          tempAllocation: null,
          allocation: newAllocation,
          history: [
            ...unit.history.map((history: IUnitHistory) => {
              return {
                ...history,
                user: history.user ? history.user._id : null,
              };
            }),
            {
              type: 'Status/Price',
              description: description,
              timestamp: new Date(),
              user: user._id,
            },
          ],
        },
      },
    });

    if (filteredDeal.worksheet) {
      await updateWorksheet({
        variables: {
          _id: filteredDeal.worksheet._id,
          record: {
            managerApproval: null,
            deal: null,
            status: 'archived',
            reason: dialogType === 'cancel' ? 'Deal has been collapsed' : 'Purchaser(s) have rescinded',
          },
        },
      });
    }

    let documentIds = await filteredDeal.documents.map((document: IDocuments) => document._id);
    if (dialogType === 'cancel') {
      await deleteManyDocuments({ variables: { ids: documentIds, deleteFile: false } });
      await updateDeal({
        variables: { _id: filteredDeal._id, record: { cancelled: { dateCancelled: new Date(), reason: reason, type: cancelType } } },
      });
    } else {
      await deleteManyDocuments({ variables: { ids: documentIds, deleteFile: false } });
      await updateDeal({ variables: { _id: filteredDeal._id, record: { rescission: { dateRescinded: new Date(), reason: reason } } } });
    }
  };

  return (
    <CustomDialog
      handleClose={() => {
        setDialogOpen(false);
        setDialogType('');
      }}
      handleCloseRemove={() => {
        setDialogOpen(false);
        setDialogType('');
      }}
      handleCloseSuccess={() => handleCloseSuccess('cancel')}
      handleCloseSecond={() => handleCloseSuccess('recreate')}
      open={dialogOpen}
      removeButton={'Cancel'}
      successButton={`Yes, I would like to ${capitalizeFirstLetter(dialogType)} this deal.`}
      secondOption={dialogType === 'cancel' ? 'Void and Recreate' : null}
      dialogContent={dialogContent()}
      dialogTitle={`Are you sure you would like to ${dialogType}`}
      checked={checked}
      maxWidth={'xl'}
    />
  );
};

interface ChildProps {
  dialogOpen: boolean;
  dialogType: string;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
  setDialogType: Dispatch<SetStateAction<string>>;
}

const UPDATEREGISTRANT = gql`
  mutation updateRegistrantStatus($email: String!, $project: MongoID!, $status: String!, $rating: String!, $type: String!) {
    updateRegistrantStatus(email: $email, project: $project, status: $status, rating: $rating, type: $type) {
      _id
    }
  }
`;

const UPDATEUNIT = gql`
  mutation unitUpdateById($_id: MongoID!, $record: UpdateByIdUnitInput!) {
    unitUpdateById(_id: $_id, record: $record) {
      record {
        _id
        suite
        unit
        level
        modelType
        basePrice
        size
        status
        getUrl
        putUrl
        bathroom
        exposure
        status
        outdoorSize
        outdoorType
        unitType
        originalPrice
        basePrice
        allocation {
          _id
          fullName
        }
        history {
          type
          description
          timestamp
          user {
            _id
            fullName
          }
          _id
        }
      }
    }
  }
`;

const UPDATEWORKSHEET = gql`
  mutation worksheetUpdateById($_id: MongoID!, $record: UpdateByIdWorksheetInput!) {
    worksheetUpdateById(_id: $_id, record: $record) {
      record {
        _id
        status
      }
    }
  }
`;

const DELETEMANYDOCUMENTS = gql`
  mutation documentDeleteManyById($ids: [MongoID!], $deleteFile: Boolean!) {
    documentDeleteManyById(ids: $ids, deleteFile: $deleteFile)
  }
`;

const UPDATEDEAL = gql`
  mutation dealUpdateById($_id: MongoID!, $record: UpdateByIdDealInput!) {
    dealUpdateById(_id: $_id, record: $record) {
      record {
        purchasers {
          firstName
          lastName
          fullName
        }
        rescission {
          dateRescinded
          reason
        }
        documents {
          _id
        }
        cancelled {
          dateCancelled
          type
          reason
        }
      }
    }
  }
`;

export default VoidDialog;
