import { useContext, useEffect, useState } from 'react';
import { Buffer } from 'buffer';
import { Button, Box } from '@mui/material';
import { CreateDealContext } from '../../../context/CreateDealContext';
import PDFGenerator from '../../common/PDFGenerator';
import { IMergeArray } from '../../../types/merge';
import { IAutoDocuments } from '../../../types/CreateDealForm';
import { FlexBetween } from '../../../commonStyles';
import { useSelector } from 'react-redux';
import { selectProject } from '../../../features/project/projectSlice';
import { selectUser } from '../../../features/auth/authSlice';
import { useAppDispatch } from '../../../app/hooks';
import { showErrorSnackbar } from '../../../features/snackbar/snackbarSlice';
import { useNavigate } from 'react-router-dom';
import { MergeContext } from '../../../context/MergeContext';
import { IOption } from '../../../types/project';

const ReviewStep = (props: ChildProps) => {
  const { createDeal } = props;
  const storeDispatch = useAppDispatch();
  const navigate = useNavigate();
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const {
    suiteInfo,
    setActiveStep,
    activeStep,
    selectedUnit,
    realtorId,
    purchaserId,
    apsPdf,
    totalPdf,
    displayPdf,
    selectedAps,
    selectedMergeTemplates,
    openCustom,
    noRealtor,
    validEmails,
    autoDocuments,
    executor,
    selectedDeposit,
    coopRates,
    rental,
  } = useContext(CreateDealContext);
  const { setRef } = useContext(MergeContext);
  const [downloadUrl, setDownloadUrl] = useState<any>(null);

  useEffect(() => {
    createUrl();
  }, []);

  const sendDeal = async (inPerson: boolean, test: boolean) => {
    if (
      selectedUnit.status === 'IP' ||
      selectedUnit.status === 'O' ||
      selectedUnit.status === 'C' ||
      selectedUnit.status === 'F' ||
      selectedUnit.status === 'S'
    ) {
      storeDispatch(showErrorSnackbar('There is already an active deal for this unit'));
      navigate(`/${project._id}/dashboard/price-grid`);
      return;
    }

    if (selectedUnit.status === 'UT') {
      storeDispatch(showErrorSnackbar('Another purchaser is looking to unit transfer to this unit'));
      navigate(`/${project._id}/dashboard/price-grid`);
      return;
    }

    let apsSignField = selectedAps.signFields;
    let floorplan = selectedUnit.floorPlan;
    let apsFloorPlan = project.mergeTemplates.find((mergeTemplate: any) => mergeTemplate.name === 'floorplan');
    let apsTemplate = apsFloorPlan?.apsTemplates.find((mergeTemplate: any) => mergeTemplate.apsTemplate._id === selectedAps._id);

    if (!apsFloorPlan) return storeDispatch(showErrorSnackbar('No Floorplan for Project'));
    if (!apsTemplate) return storeDispatch(showErrorSnackbar('No Page Number Assigned on Floor Plan'));

    let apsPageNumber = await apsSignField.map((aps: IMergeArray) => {
      if (aps?.pageNumber! > apsTemplate?.pageNumber!) {
        return {
          ...aps,
          pageNumber: aps.pageNumber! + (selectedUnit.floorPlan.totalPages - 1),
          x: Math.round(aps.x),
          y: Math.round(aps.y),
          type: null,
        };
      } else {
        return {
          ...aps,
          x: Math.round(aps.x),
          y: Math.round(aps.y),
          type: null,
        };
      }
    });

    let floorPlanPageNumber = await floorplan.signFields.map((floorPlan: IMergeArray, index: number) => {
      if (index === 0) {
        return {
          ...floorPlan,
          x: Math.round(floorPlan.x),
          y: Math.round(floorPlan.y),
          pageNumber: apsTemplate?.pageNumber! + floorPlan?.pageNumber!,
          type: 'floorplan',
          totalPages: 1,
        };
      } else {
        return {
          ...floorPlan,
          x: Math.round(floorPlan.x),
          y: Math.round(floorPlan.y),
          pageNumber: apsTemplate?.pageNumber! + floorPlan?.pageNumber!,
          type: 'floorplan',
          totalPages: 0,
        };
      }
    });

    let signFieldArray: IMergeArray[] = [...apsPageNumber, ...floorPlanPageNumber];

    if (selectedMergeTemplates.length > 0) {
      let scheduleTotalSignFields: IMergeArray[] = [];
      for (const [index, merge] of selectedMergeTemplates.entries()) {
        let apsSchedules = await project.mergeTemplates.find(
          (mergeTemplate: any) =>
            mergeTemplate.name === merge.name || (mergeTemplate.mergeTemplate && mergeTemplate.mergeTemplate._id === merge._id)
        );

        let schedulePageNumber = await apsSchedules?.apsTemplates!.find(
          (mergeTemplate: any) => mergeTemplate.name === selectedAps.name || mergeTemplate.apsTemplate._id === selectedAps._id
        );
        let scheduleSignFields = await merge.signFields.map((signFields: any, index: number) => {
          let pageNumber = signFields.pageNumber + schedulePageNumber?.pageNumber;
          if (index === 0) {
            return {
              ...signFields,
              x: Math.round(signFields.x),
              y: Math.round(signFields.y),
              pageNumber: pageNumber,
              type: merge.name,
              totalPages: merge.totalPages,
            };
          } else {
            return {
              ...signFields,
              x: Math.round(signFields.x),
              y: Math.round(signFields.y),
              pageNumber: pageNumber,
              type: merge.name,
              totalPages: 0,
            };
          }
        });

        scheduleTotalSignFields = [...scheduleTotalSignFields, ...scheduleSignFields];
      }
      signFieldArray = [...apsPageNumber, ...floorPlanPageNumber, ...scheduleTotalSignFields];
    }

    signFieldArray.sort((a: any, b: any) => {
      if (a.pageNumber === b.pageNumber) {
        if (a.type === 'floorplan') {
          return -1;
        } else if (!a.type) {
          return 1;
        } else if (!b.type) {
          return -1;
        } else {
          return 1;
        }
      } else return parseFloat(a.pageNumber) - parseFloat(b.pageNumber);
    });

    let adjustedPageNumbers = await signFieldArray.map((signField: any, index: number) => {
      let sum = signField.pageNumber;
      for (let i = 0; i < index; i++) {
        if (signField.type) {
          if (signFieldArray[i].type && signField.type !== signFieldArray[i].type && i !== index) {
            sum += signFieldArray[i].totalPages;
          }
        } else {
          if (signFieldArray[i].type === 'floorplan' && signField.pageNumber >= signFieldArray[i]?.pageNumber!) {
            console.log('');
          } else if (
            signFieldArray[i].type &&
            signField.pageNumber === signFieldArray[i].pageNumber &&
            signField.pageNumber > apsTemplate?.pageNumber!
          ) {
            sum += signFieldArray[i].totalPages;
          } else if (signFieldArray[i].totalPages) {
            sum += signFieldArray[i].totalPages;
          }
        }
      }
      return {
        ...signField,
        pageNumber: sum,
      };
    });

    const deposits = suiteInfo.deposit.map((d: any) => {
      delete d.chequeDate;
      delete d.accountNumber;
      return d;
    });

    // prep signFields
    // const signFields = mergeTemplateArray.reduce((acc: IMergeArray[], curr) => acc.concat(curr.signFields || []), []);

    // prep file
    const blob = new Blob([apsPdf]);
    const file = new File([blob], 'aps.pdf', { type: 'application/pdf' });

    let autoDocumentsFile: IAutoDocuments[] = [];

    if (autoDocuments.length > 0) {
      autoDocumentsFile = await Promise.all(
        autoDocuments.map(async (autoDocument: IAutoDocuments) => {
          let blob = new Blob([autoDocument.file]);
          let file = new File([blob], `${autoDocument.name}.pdf`, { type: 'application/pdf' });

          let signFields = await Promise.all(
            autoDocument.signFields.map((sign: any) => {
              return {
                index: sign.index,
                key: sign.key,
                pageNumber: sign.pageNumber,
                x: Math.round(sign.x),
                y: Math.round(sign.y),
              };
            })
          );

          return {
            ...autoDocument,
            signFields: signFields,
            file: file,
          };
        })
      );
    }

    let newSignField = await adjustedPageNumbers.map((sign) => {
      return {
        index: sign.index,
        key: sign.key,
        pageNumber: sign.pageNumber,
        x: Math.round(sign.x),
        y: Math.round(sign.y),
      };
    });

    let suiteTags: string[] = [];
    if (noRealtor.noRealtor) {
      suiteTags = [...suiteInfo.tags, noRealtor.noRealtorType];
    }

    // Remove Rental from Suite Options
    let suiteOptions = suiteInfo.options.map(({ rental, ...attrs }: IOption) => attrs);

    // Add Additional Documents to end of envelope
    const args = {
      basePrice: parseFloat(selectedUnit.basePrice),
      user: user._id,
      comments: suiteInfo.comments,
      deposit: deposits,
      depositName: selectedDeposit,
      documents: [
        {
          name: 'APS',
          file,
          isAPS: true,
          type: 'APS',
          signFields: newSignField,
          mergeTemplate: selectedAps._id,
        },
      ],
      unit: selectedUnit._id,
      salesRep: suiteInfo.salesRep,
      options: suiteOptions,
      project: project._id,
      purchasers: purchaserId,
      realtor: realtorId,
      tags: suiteTags,
      cc: validEmails,
      executor: executor,
      autoDocuments: autoDocumentsFile,
      inPerson: inPerson,
      coopRates: coopRates,
      rental: rental ? parseInt(rental, 10) : null,
      test: test,
    };

    createDeal({ variables: args });
  };

  const handleRef = (ref: any) => {
    setRef(ref);
  };

  const createUrl = () => {
    const binaryData = Buffer.from(totalPdf, 'binary');
    const blob = new Blob([binaryData], { type: 'application/pdf' });
    const previewUrl = URL.createObjectURL(blob);
    setDownloadUrl(previewUrl);
    return null;
  };

  const downloadCreatedMerge = () => {
    const element = document.createElement('a');
    element.href = downloadUrl;
    element.target = '_blank';
    element.click();
  };

  return (
    <div>
      {!openCustom ? (
        <FlexBetween sx={{ my: 2 }}>
          <Button variant="contained" color="primary" onClick={() => setActiveStep(activeStep - 1)}>
            Back
          </Button>
          <Box>
            <Button sx={{ mr: 1 }} color="primary" variant="contained" onClick={() => downloadCreatedMerge()}>
              Download
            </Button>
            <Button sx={{ mr: 1 }} color="success" variant="contained" onClick={() => sendDeal(false, true)}>
              Send Test Deal
            </Button>
            <Button sx={{ mr: 1 }} color="success" variant="contained" onClick={() => sendDeal(false, false)}>
              Send Deal Remotely
            </Button>
            <Button color="success" variant="contained" onClick={() => sendDeal(true, false)}>
              In Person Signing
            </Button>
          </Box>
        </FlexBetween>
      ) : null}
      <PDFGenerator merge={displayPdf} type={'base64'} floorPlan={openCustom} handleRef={handleRef} />
      {!openCustom ? (
        <FlexBetween sx={{ my: 2 }}>
          <Button variant="contained" color="primary" onClick={() => setActiveStep(activeStep - 1)}>
            Back
          </Button>
          <Box>
            <Button sx={{ mr: 1 }} color="primary" variant="contained" onClick={() => downloadCreatedMerge()}>
              Download
            </Button>
            <Button sx={{ mr: 1 }} color="success" variant="contained" onClick={() => sendDeal(false, true)}>
              Send Test Deal
            </Button>
            <Button sx={{ mr: 1 }} color="success" variant="contained" onClick={() => sendDeal(false, false)}>
              Send Deal Remotely
            </Button>
            <Button color="success" variant="contained" onClick={() => sendDeal(true, false)}>
              In Person Signing
            </Button>
          </Box>
        </FlexBetween>
      ) : null}
    </div>
  );
};

interface ChildProps {
  createDeal: any;
}

export default ReviewStep;
