import { useContext, useState } from 'react';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import Dropzone from 'react-dropzone';
import { Box, Grid, Button, Tooltip, TextField, InputAdornment, FormControlLabel, Checkbox } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import { useSelector } from 'react-redux';

import { useCreateActivity } from '../../../features/activity/activityHooks';
import { useAppDispatch } from '../../../app/hooks';
import { selectProject } from '../../../features/project/projectSlice';
import { selectUser } from '../../../features/auth/authSlice';
import { showSuccessSnackbar, showErrorSnackbar } from '../../../features/snackbar/snackbarSlice';
import { accessAllowed } from '../../../features/project/projectHooks';
import { UnitContext } from '../../../context/UnitContext';
import { chequeTypes } from '../../../utils/Constants';
import { IDealDeposit } from '../../../types/CreateDealForm';
import DealDeposits from '../DealDeposits';
import PdfCard from '../../common/PdfCard';
import Dropdown from '../../common/formControls/Dropdown';
import { IMerge, IMergeData } from '../../../types/merge';
import { IDocuments } from '../../../types/unit';
import { createPdf, numToCurrency } from '../../../utils/Functions';
import { FlexBetween, FlexEnd, Flex } from '../../../commonStyles';
import LoadingWrapper from '../../common/LoadingWrapper';

const DepositInfo = (props: ChildProps) => {
  const {
    handleDrop,
    style,
    createDeposits,
    openDialog,
    depositList,
    addPendingDeposits,
    handleDepositSubmit,
    selectedDeposit,
    setSelectedDeposit,
  } = props;
  const {
    unit,
    filteredDeal,
    setFilteredDeal,
    documents,
    setDocuments,
    pendingDeposits,
    setPendingDeposits,
    updateDealDocuments,
    pendingBasePrice,
    setPendingBasePrice,
    document,
  } = useContext(UnitContext);
  const createActivity = useCreateActivity();
  const storeDispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const project = useSelector(selectProject);

  const [errors, setErrors] = useState<any>(null);
  const [selectedDepositMerge, setSelectedDepositMerge] = useState('');
  const [depositMerges, setDepositMerges] = useState<any[]>([]);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [changeBasePrice, setChangeBasePrice] = useState<boolean>(false);

  // Queries and Mutations

  const [getDepositMerges] = useLazyQuery<IMergeData>(MERGETEMPLATES, {
    variables: { filter: { project: project._id, type: 'Deposit' } },
    onCompleted: (data) => {
      setDepositMerges(data.mergeTemplateMany);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [createDepositImages, { loading: savingCheques }] = useMutation(DEPOSITIMAGES, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar(`Deposit Images have been uploaded`));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [deleteCheques] = useMutation(DELETECHEQUES, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar(`Cheque Image has been deleted!`));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [moveDocuments] = useMutation(MOVEDOCUMENTS, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar(`Cheque Image has been moved to Miscellaneous!`));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [createDepositAmendment] = useMutation(CREATEDOCUMENT, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar(`Deposit Amendment have been created`));
      createActivity({
        project: project._id,
        user: user._id,
        deal: filteredDeal._id,
        title: `Deposit Amendment`,
        content: 'Deposit Amendment has been created',
      });
      let documentIds = documents.map((document: IDocuments) => {
        return document._id;
      });
      let record: any = { depositName: selectedDeposit, documents: [...documentIds, data.documentCreateOne._id] };
      if (pendingBasePrice) {
        record.pendingBasePrice = parseInt(pendingBasePrice, 10);
      }
      updateDealDocuments({
        variables: {
          _id: filteredDeal._id,
          record: record,
        },
      });
      setDocuments([...documents, data.documentCreateOne]);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [renameDepositImage] = useMutation(RENAMEDEPOSIT, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar(`Renamed to ${data.depositImageRename}`));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  // Functions

  const handleDepositsArray = (array: IDealDeposit[], type: string) => {
    if (type === 'additional') {
      setFilteredDeal({
        ...filteredDeal,
        additionalDeposits: array,
      });
    } else if (type === 'pending') {
      setFilteredDeal({
        ...filteredDeal,
        pendingDeposits: array,
      });
    } else {
      setFilteredDeal({
        ...filteredDeal,
        deposit: array,
      });
    }
  };

  const handleDelete = async (numIndex: number, id: string) => {
    if (filteredDeal.depositImages[numIndex]._id) {
      await deleteCheques({
        variables: { _id: filteredDeal._id, documentId: filteredDeal.depositImages[numIndex]._id, deleteFile: true, type: 'cheques' },
      });
    }
    let filterDepositImages = filteredDeal.depositImages.filter((depositImages: any, index: number) => {
      return index !== numIndex;
    });
    setFilteredDeal({
      ...filteredDeal,
      depositImages: filterDepositImages,
    });
  };

  const handleMoveImage = async (numIndex: number, id: string) => {
    if (filteredDeal.depositImages[numIndex]._id) {
      await moveDocuments({
        variables: {
          _id: filteredDeal._id,
          documentId: filteredDeal.depositImages[numIndex]._id,
          current: 'cheques',
          destination: 'miscellaneous',
        },
      }).then((res) => {
        let filterDepositImages = filteredDeal.depositImages.filter((depositImages: any, index: number) => {
          return index !== numIndex;
        });
        setFilteredDeal({
          ...filteredDeal,
          depositImages: filterDepositImages,
          miscellaneous: res.data.moveDocuments.miscellaneous,
        });
      });
    }
  };

  const saveDepositImages = async () => {
    if (filteredDeal.depositImages.length > 0) {
      if (filteredDeal.depositImages.some((file: any) => file.file)) {
        await filteredDeal.depositImages.forEach((file: any, index: number) => {
          if (file.file) {
            createDepositImages({ variables: { id: filteredDeal._id, name: file.file.name, file: file.file } });
          }
        });
      } else return storeDispatch(showErrorSnackbar(`No Uploaded Deposit Images`));
    }
  };

  const handlePendingSubmit = async (e: any) => {
    e.preventDefault();
    if (errors) {
      storeDispatch(showErrorSnackbar(`Invalid Date`));
      return;
    }
    if (!selectedDeposit) {
      storeDispatch(showErrorSnackbar(`No Deposit Structure Selected`));
      return;
    }
    if (pendingDeposits.length === 0) {
      storeDispatch(showErrorSnackbar(`No Deposits Set`));
      return;
    }
    let pending = pendingDeposits.map((pendingDeposits: IDealDeposit, index: number) => {
      if (
        filteredDeal.deposit[index] &&
        pendingDeposits.amount === filteredDeal.deposit[index].amount &&
        pendingDeposits.name === filteredDeal.deposit[index].name &&
        new Date(pendingDeposits.dueDate!).setHours(0, 0, 0, 0) === new Date(filteredDeal.deposit[index].dueDate).setHours(0, 0, 0, 0)
      ) {
        return {
          accountNumber: filteredDeal.deposit[index].accountNumber,
          chequeAmount: filteredDeal.deposit[index].chequeAmount,
          chequeDate: filteredDeal.deposit[index].chequeDate,
          chequeNumber: filteredDeal.deposit[index].chequeNumber,
          chequeType: filteredDeal.deposit[index].chequeType,
          amount: filteredDeal.deposit[index].amount,
          deal: filteredDeal._id,
          dueDate: filteredDeal.deposit[index].dueDate,
          name: filteredDeal.deposit[index].name,
        };
      } else {
        return {
          accountNumber: pendingDeposits.accountNumber,
          amount: pendingDeposits.amount,
          deal: filteredDeal._id,
          dueDate: pendingDeposits.dueDate,
          name: pendingDeposits.name,
        };
      }
    });

    let depositAmendment = depositMerges.find((merge: IMerge) => {
      return merge.name === selectedDepositMerge;
    });

    if (!depositAmendment) {
      storeDispatch(showErrorSnackbar(`No Deposit Amendment found`));
      return;
    }

    let pdf = await createPdf(
      depositAmendment,
      project,
      unit,
      filteredDeal.purchasers,
      filteredDeal.deposit,
      filteredDeal.options,
      null,
      null,
      filteredDeal.options,
      pendingDeposits,
      { ...filteredDeal, pendingBasePrice: pendingBasePrice }
    );

    let sign = depositAmendment?.signFields.map((sign: any) => {
      return {
        index: sign.index,
        key: sign.key,
        pageNumber: sign.pageNumber,
        x: sign.x,
        y: sign.y,
      };
    });
    const args = {
      status: 'Prepared',
      name: depositAmendment?.name,
      project: project._id,
      deal: filteredDeal._id,
      signFields: sign,
      // signers,
      type: 'Deposit',
      isAPS: false,
      mergeTemplate: depositAmendment?._id,
    };

    await createDepositAmendment({ variables: { record: args, file: pdf } });
    await createDeposits({ variables: { records: pending } });
  };

  const handlePendingDepositsArray = (array: IDealDeposit[]) => {
    setPendingDeposits(array);
  };

  const tooltip = () => {
    return (
      <Flex sx={{ flexDirection: 'column' }}>
        {chequeTypes.map((type: any) => {
          return (
            <Flex sx={{ flexWrap: 'wrap', textAlign: 'left', mt: 1 }}>
              <Box
                sx={{
                  width: '25px',
                  height: '15px',
                  border: '1px solid #000',
                  alignSelf: 'center',
                  marginRight: '10px',
                  backgroundColor: type.color,
                }}
              ></Box>
              <Box style={{ textAlign: 'left', marginRight: '10px' }}>{type.value}</Box>
            </Flex>
          );
        })}
      </Flex>
    );
  };

  const handleEdit = (value: string, numIndex: number) => {
    if (filteredDeal.depositImages[numIndex].file) {
      let images = filteredDeal.depositImages.map((image: any, index: number) => {
        if (numIndex === index) {
          const newFile = new File([image.file], value, { type: image.file.type });
          return {
            ...image,
            file: newFile,
          };
        } else return image;
      });
      setFilteredDeal({
        ...filteredDeal,
        depositImages: images,
      });
    } else {
      renameDepositImage({
        variables: { _id: filteredDeal._id, project: project._id, originalName: filteredDeal.depositImages[numIndex].name, name: value },
      }).then((res: any) => {
        let images = filteredDeal.depositImages.map((image: any, index: number) => {
          if (numIndex === index) {
            return {
              ...image,
              name: res.data.depositImageRename,
            };
          } else return image;
        });
        setFilteredDeal({
          ...filteredDeal,
          depositImages: images,
        });
      });
    }
  };

  return savingCheques ? (
    <LoadingWrapper title="Saving Deposit Images" modal={true} height={'120vh'} />
  ) : (
    <div>
      <form onSubmit={handleDepositSubmit} autoComplete="off">
        {filteredDeal.deposit ? (
          <>
            <h3
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                // '@media (max-width: 600px)': {
                //   flexDirection: 'column',
                // }
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ mr: 1 }}>
                  <em>Current Deposits</em>
                </Box>
                <Tooltip title={tooltip()}>
                  <InfoIcon sx={{ alignSelf: 'center' }} />
                </Tooltip>
              </Box>
              {!documents.length || (documents[0].type === 'APS' && documents[0].status === 'Completed' && !documents[0].dsEnvelopeId) ? (
                <Box>
                  <EditIcon onClick={() => setDisabled(!disabled)} sx={{ cursor: 'pointer' }} />
                </Box>
              ) : null}
            </h3>
            <DealDeposits
              deposit={filteredDeal.deposit}
              basePrice={filteredDeal!.totalPrice!}
              handleDepositsArray={handleDepositsArray}
              depositType={''}
              disable={disabled}
              setErrors={setErrors}
            />
            {filteredDeal.pendingDeposits.length > 0 ? (
              <div>
                <h3
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                  }}
                >
                  <em>Pending Deposits</em>
                </h3>
                <em>
                  These deposits are currently pending until the Deposit/Options Amendment has been executed. After the amendment has been
                  executed, it will replace the deposits above. If you would like to remove these deposits, please delete the Deposit/Option
                  Amendment in the amendments section
                </em>
                {filteredDeal.pendingBasePrice ? (
                  <Box sx={{ my: 2 }}>
                    Pending Base Price Change: <strong>{numToCurrency.format(filteredDeal.pendingBasePrice)}</strong>
                  </Box>
                ) : null}
                <DealDeposits
                  deposit={filteredDeal.pendingDeposits}
                  basePrice={filteredDeal!.totalPrice!}
                  handleDepositsArray={handleDepositsArray}
                  depositType={'pending'}
                  disable={true}
                  setErrors={setErrors}
                />
              </div>
            ) : null}
            {filteredDeal.additionalDeposits.length > 0 ? (
              <>
                <h3>Additional Deposits</h3>
                <DealDeposits
                  deposit={filteredDeal.additionalDeposits}
                  basePrice={filteredDeal!.totalPrice!}
                  handleDepositsArray={handleDepositsArray}
                  depositType={'additional'}
                  disable={true}
                  setErrors={setErrors}
                />
              </>
            ) : null}
            {accessAllowed(user, project._id, 'editDeals') &&
            !filteredDeal.cancelled.dateCancelled &&
            !filteredDeal.rescission.dateRescinded ? (
              <FlexBetween
                sx={{
                  flexDirection: 'row-reverse',
                  mt: 2,
                }}
              >
                <Button type="submit" name={'update'} color="success" variant="contained">
                  Save Deposit Information
                </Button>
              </FlexBetween>
            ) : null}
            <h2>Deposit Images</h2>
            <Box sx={{ mb: 3 }}>Upload any Cheque or Wire Transfers here</Box>
            <div>
              <Dropzone onDrop={(files) => handleDrop(files, 0, 'deposit')} accept="image/jpg, image/jpeg, image/png, application/pdf">
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      <p>Drag and Drop or Upload Deposit Images</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            <div>
              <Grid
                container
                spacing={2}
                style={{
                  margin: 0,
                  width: '100%',
                }}
              >
                {filteredDeal.depositImages.length > 0
                  ? filteredDeal.depositImages.map((attachment: any, index: number) => {
                      return (
                        <Grid key={index} item lg={3} md={4} sm={6} xs={12}>
                          <PdfCard
                            file={attachment.getUrl ? attachment.getUrl : attachment.url}
                            title={attachment.name ? attachment.name : attachment.file.name}
                            id={index}
                            handleDelete={handleDelete}
                            download={true}
                            index={index}
                            handleMoveImage={handleMoveImage}
                            handleEdit={handleEdit}
                          />
                        </Grid>
                      );
                    })
                  : null}
              </Grid>
              {accessAllowed(user, project._id, 'editDeals') ? (
                <FlexEnd
                  sx={{
                    flexWrap: 'wrap',
                    '@media (max-width: 600px)': {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <FlexBetween sx={{ flexDirection: 'row-reverse' }}>
                    <Button onClick={() => saveDepositImages()} name={'update'} color="success" variant="contained">
                      Upload Cheque Images
                    </Button>
                  </FlexBetween>
                </FlexEnd>
              ) : null}
            </div>
          </>
        ) : null}
      </form>
      <form onSubmit={handlePendingSubmit}>
        {pendingDeposits.length > 0 && document === 'Deposits' ? (
          <>
            <h2>Pending Deposits</h2>
            <FormControlLabel
              sx={{ mb: 2 }}
              control={
                <Checkbox
                  name="changeBasePrice"
                  color="primary"
                  checked={changeBasePrice}
                  onClick={(e) => setChangeBasePrice(!changeBasePrice)}
                />
              }
              label={'Change Deal Base Price'}
            />
            {changeBasePrice ? (
              <TextField
                sx={{ mb: 2 }}
                title={'Change Base Price'}
                name={'basePrice'}
                fullWidth
                type="number"
                onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                value={pendingBasePrice}
                label={'Change Base Price'}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPendingBasePrice(e.target.value);
                }}
              />
            ) : null}
            <Dropdown
              title={'Deposit Type'}
              menuList={depositList()}
              name={'depositType'}
              handleSelect={(event: any) => setSelectedDeposit(event.target.value)}
              value={selectedDeposit}
            />
            <DealDeposits
              deposit={pendingDeposits}
              basePrice={
                pendingBasePrice
                  ? filteredDeal.options.reduce((a: any, b: any) => {
                      return a + b.amount;
                    }, parseInt(pendingBasePrice, 10))
                  : filteredDeal!.totalPrice!
              }
              handleDepositsArray={handlePendingDepositsArray}
              disable={false}
              setErrors={setErrors}
            />
            <Box sx={{ mt: 2 }}>
              <Dropdown
                title={'Select Deposit Amendment'}
                menuList={depositMerges.map((deposit: IMerge) => deposit.name)}
                name={'deposit'}
                handleSelect={(e: any) => setSelectedDepositMerge(e.target.value)}
                value={selectedDepositMerge ? selectedDepositMerge : ''}
              />
            </Box>
          </>
        ) : null}
        {accessAllowed(user, project._id, 'editDeals') &&
        !filteredDeal.cancelled.dateCancelled &&
        !filteredDeal.rescission.dateRescinded ? (
          <Box sx={{ mt: 2 }}>
            {pendingDeposits.length > 0 && document === 'Deposits' ? (
              <FlexBetween sx={{ flexWrap: 'wrap' }}>
                <div>
                  <Button onClick={() => addPendingDeposits()} name={'update'} color="primary" variant="contained">
                    Add Pending Deposit
                  </Button>
                </div>
                <Box sx={{ flexWrap: 'wrap' }}>
                  <Button sx={{ mr: 1 }} onClick={() => openDialog('deleteDeposits')} name={'update'} color="error" variant="contained">
                    Delete Pending Deposits
                  </Button>
                  <Button type="submit" name={'update'} color="success" variant="contained">
                    Create Deposit Amendment
                  </Button>
                </Box>
              </FlexBetween>
            ) : pendingDeposits.length === 0 ? (
              <Button
                onClick={() => {
                  getDepositMerges();
                  addPendingDeposits('Document');
                }}
                name={'update'}
                color="primary"
                variant="contained"
              >
                Create Deposit Amendment
              </Button>
            ) : null}
          </Box>
        ) : null}
      </form>
    </div>
  );
};

interface ChildProps {
  handleDrop: any;
  style: any;
  openDialog: any;
  createDeposits: any;
  depositList: any;
  addPendingDeposits: any;
  handleDepositSubmit: any;
  selectedDeposit: any;
  setSelectedDeposit: any;
}

const DELETECHEQUES = gql`
  mutation deleteSupportDocuments($_id: MongoID!, $documentId: MongoID!, $deleteFile: Boolean!, $type: String!) {
    deleteSupportDocuments(_id: $_id, documentId: $documentId, deleteFile: $deleteFile, type: $type) {
      mortgage {
        lender
        amount
      }
      miscellaneous {
        _id
        name
        getUrl
      }
    }
  }
`;

const MOVEDOCUMENTS = gql`
  mutation moveDocuments($_id: MongoID!, $documentId: MongoID!, $current: String!, $destination: String!) {
    moveDocuments(_id: $_id, documentId: $documentId, current: $current, destination: $destination) {
      depositImages {
        getUrl
        name
      }
      miscellaneous {
        _id
        name
        getUrl
      }
    }
  }
`;

const DEPOSITIMAGES = gql`
  mutation depositImageUpload($id: MongoID!, $name: String!, $file: Upload!) {
    depositImageUpload(id: $id, name: $name, file: $file) {
      depositImages {
        _id
        name
        getUrl
      }
    }
  }
`;

const CREATEDOCUMENT = gql`
  mutation documentCreateOne($record: CreateOneDocumentInput, $file: Upload!) {
    documentCreateOne(record: $record, file: $file) {
      _id
      name
      project {
        _id
      }
      status
      isAPS
      type
      dsEnvelopeId
    }
  }
`;

const RENAMEDEPOSIT = gql`
  mutation depositImageRename($_id: MongoID!, $project: MongoID!, $originalName: String!, $name: String!) {
    depositImageRename(_id: $_id, project: $project, originalName: $originalName, name: $name)
  }
`;

const MERGETEMPLATES = gql`
  query mergeTemplateMany($filter: FilterFindManyMergeTemplateInput) {
    mergeTemplateMany(filter: $filter) {
      _id
      project {
        _id
      }
      name
      mergeFields {
        key
        index
        pageNumber
        x
        y
        fontSize
        format
        wrap
      }
      signFields {
        key
        index
        pageNumber
        x
        y
        name
      }
      default
      totalPages
      type
      getUrl
      putUrl
    }
  }
`;

export default DepositInfo;
